import React, { useState, useEffect, useRef, useCallback } from 'react';
import { auth, storage } from '../firebaseConfig';
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject, getMetadata, updateMetadata} from 'firebase/storage';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import NavBar from './NavBar';
import Sidebar from './Sidebar'; 
import './Base.css';
import { useNavigate } from 'react-router-dom';
import * as fontkit from 'fontkit'; 
import { PDFDocument, rgb} from 'pdf-lib';
import { FaPlay, FaFileAlt, FaPen, FaTrash, FaPause, FaForward, FaBackward, FaTimes } from 'react-icons/fa';
import OpenAI from "openai";

// OpenAI API setup
const openai = new OpenAI({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,   
    dangerouslyAllowBrowser: true,
  })

let globalNoteNumber = 1; // Global variable to persist noteNumber across pages

const Recordings: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [isDragging, setIsDragging] = useState(false);
  const [textareaContent, setTextareaContent] = useState<string>('');
  const [noteNumber, setNoteNumber] = useState<number>(globalNoteNumber);
  const [userUID, setUserUID] = useState<string>('');
  const [isAnonymous, setIsAnonymous] = useState<boolean>(true);
  const isProfileFetched = useRef<boolean>(false); // Using ref instead of state
  const [isConverting, setIsConverting] = useState<boolean>(false); 
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingProgress, setRecordingProgress] = useState<number>(0); 
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null); 
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null); 
  const recordingInterval = useRef<NodeJS.Timeout | null>(null); 
  const [customFileName, setCustomFileName] = useState<string>(''); 
  const [showFileNamePrompt, setShowFileNamePrompt] = useState<boolean>(false);
  const [showScriptPrompt, setShowScriptPrompt] = useState<boolean>(false);
  const [playingAudio, setPlayingAudio] = useState<string | null>(null);
  const [recordings, setRecordings] = useState<any[]>([]);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [audioProgress, setAudioProgress] = useState<number>(0); // Audio progress state
  const [isPlaying, setIsPlaying] = useState(false); // Play/Pause state
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1.0);
  const [metadataDuration, setMetadataDuration] = useState<number | null>(null); 


  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);


  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdownElement = document.querySelector('.menu-content');
      const isClickInside = dropdownElement?.contains(event.target as Node);
      
      // If the click is outside the dropdown, close it
      if (!isClickInside) {
        setOpenMenuIndex(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  // Fetch user profile and subscription status
  useEffect(() => {
    const fetchUserProfile = async (uid: string) => {
      const firestore = getFirestore();
      const userDocRef = doc(firestore, 'users', uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        if (userData && userData.noteNumber) {
          setNoteNumber(userData.noteNumber);
        }
      }
      isProfileFetched.current = true;
    };

    const resetNoteNumberForAnonymousUser = () => {
      setNoteNumber(1);
    };

    const currentUser = auth.currentUser;
    if (currentUser) {
      setUserUID(currentUser.uid);
      setIsAnonymous(currentUser.isAnonymous);

      if (!currentUser.isAnonymous && !isProfileFetched.current) {
        fetchUserProfile(currentUser.uid);
      } else {
        resetNoteNumberForAnonymousUser();
      }
    }
  }, []);

  useEffect(() => {
    globalNoteNumber = noteNumber; // Update the global note number
  }, [noteNumber]);

  // Use useCallback to memoize fetchRecordings
  const fetchRecordings = useCallback(async () => {
    if (!userUID) return;

    const storageRef = ref(storage, 'recordings/');
    const result = await listAll(storageRef);
    const userFiles = result.items.filter((item) => item.name.includes(userUID));
    const filePromises = userFiles.map(async (fileRef) => {
      const url = await getDownloadURL(fileRef);
      return {
        name: fileRef.name,
        url,
        refPath: fileRef.fullPath,
      };
    });
    const files = await Promise.all(filePromises);
    setRecordings(files);
  }, [userUID]); // Only re-create the function when userUID changes

  useEffect(() => {
    if (userUID) {
      fetchRecordings(); // Safe to use fetchRecordings here now
    }
  }, [userUID, fetchRecordings]); // Add fetchRecordings as a dependency
  

  const saveNoteNumberToDatabase = async (uid: string, newNoteNumber: number) => {
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', uid);
    await setDoc(userDocRef, { noteNumber: newNoteNumber }, { merge: true });
  };

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string,loadBio: string) => {
    setUserImage(loadedImageUrl);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      window.location.href = '/';
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const filterUnsupportedCharacters = (text: string): string => {
    // Replace specific problematic characters with placeholders or remove them
    const filteredText = text
      .replace(/[\u2190-\u21FF]/g, '[ARROW]') // Arrows
      .replace(/[\u2200-\u22FF]/g, '[MATH]') // Mathematical operators
      .replace(/[\u2300-\u23FF]/g, '[TECH]') // Miscellaneous technical symbols
      .replace(/[\u2500-\u257F]/g, '[BOX]') // Box drawing
      .replace(/[\u2580-\u259F]/g, '[BLOCK]') // Block elements
      .replace(/[\u25A0-\u25FF]/g, '[GEOMETRIC]') // Geometric shapes
      .replace(/[\u2600-\u26FF]/g, '[SYMBOL]') // Miscellaneous symbols
      .replace(/[\u2700-\u27BF]/g, '[DINGBAT]') // Dingbats
      .replace(/[\u2B00-\u2BFF]/g, '[SYMBOL]') // Miscellaneous symbols and arrows
      .replace(/[\u2C60-\u2C7F]/g, '[LATIN]') // Latin Extended-C
      .replace(/[\u2E80-\u2EFF]/g, '[CJK]') // CJK Radicals Supplement
      .replace(/[\u2F00-\u2FDF]/g, '[KANGXI]') // Kangxi Radicals
      .replace(/[\u2FF0-\u2FFF]/g, '[IDEOGRAPH]') // Ideographic Description Characters
      .replace(/[\u3000-\u303F]/g, '[CJK SYMBOL]') // CJK Symbols and Punctuation
      .replace(/[\u3040-\u309F]/g, '[HIRAGANA]') // Hiragana
      .replace(/[\u30A0-\u30FF]/g, '[KATAKANA]') // Katakana
      .replace(/[\u3100-\u312F]/g, '[BOPOMOFO]') // Bopomofo
      .replace(/[\u3130-\u318F]/g, '[HANGUL]') // Hangul Compatibility Jamo
      .replace(/[\u3190-\u319F]/g, '[KANBUN]') // Kanbun
      .replace(/[\u31A0-\u31BF]/g, '[BOPOMOFO]') // Bopomofo Extended
      .replace(/[\u31F0-\u31FF]/g, '[KATAKANA]') // Katakana Phonetic Extensions
      .replace(/[\u3200-\u32FF]/g, '[ENCLOSED CJK]') // Enclosed CJK Letters and Months
      .replace(/[\u3300-\u33FF]/g, '[CJK COMPAT]') // CJK Compatibility
      .replace(/[\u3400-\u4DBF]/g, '[CJK UNIFIED]') // CJK Unified Ideographs Extension A
      .replace(/[\u4E00-\u9FFF]/g, '[CJK UNIFIED]') // CJK Unified Ideographs
      .replace(/[\uA000-\uA48F]/g, '[YI]') // Yi Syllables
      .replace(/[\uA490-\uA4CF]/g, '[YI]') // Yi Radicals
      .replace(/[\uA700-\uA71F]/g, '[MODIFIER]') // Modifier Tone Letters
      .replace(/[\uA720-\uA7FF]/g, '[LATIN]') // Latin Extended-D
      .replace(/[\uA800-\uA82F]/g, '[SYLOTI NAGRI]') // Syloti Nagri
      .replace(/[\uA830-\uA83F]/g, '[NUMERIC]') // Common Indic Number Forms
      .replace(/[\uA840-\uA87F]/g, '[PHAGS-PA]') // Phags-pa
      .replace(/[\uA880-\uA8DF]/g, '[SAURASHTRA]') // Saurashtra
      .replace(/[\uA900-\uA92F]/g, '[KAYAH]') // Kayah Li
      .replace(/[\uA930-\uA95F]/g, '[REJANG]') // Rejang
      .replace(/[\uA960-\uA97F]/g, '[HANGUL]') // Hangul Jamo Extended-A
      .replace(/[\uAC00-\uD7AF]/g, '[HANGUL]') // Hangul Syllables
      .replace(/[\uD800-\uDB7F]/g, '[SURROGATE]') // High Surrogates
      .replace(/[\uDB80-\uDBFF]/g, '[SURROGATE]') // High Private Use Surrogates
      .replace(/[\uDC00-\uDFFF]/g, '[SURROGATE]') // Low Surrogates
      .replace(/[\uE000-\uF8FF]/g, '[PRIVATE]') // Private Use Area
      .replace(/[\uF900-\uFAFF]/g, '[CJK COMPAT]') // CJK Compatibility Ideographs
      .replace(/[\uFB00-\uFB4F]/g, '[ALPHABETIC]') // Alphabetic Presentation Forms
      .replace(/[\uFB50-\uFDFF]/g, '[ARABIC]') // Arabic Presentation Forms-A
      .replace(/[\uFE00-\uFE0F]/g, '[VARIATION]') // Variation Selectors
      .replace(/[\uFE10-\uFE1F]/g, '[VERTICAL]') // Vertical Forms
      .replace(/[\uFE20-\uFE2F]/g, '[COMBINING]') // Combining Half Marks
      .replace(/[\uFE30-\uFE4F]/g, '[CJK COMPAT]') // CJK Compatibility Forms
      .replace(/[\uFE50-\uFE6F]/g, '[SMALL FORM]') // Small Form Variants
      .replace(/[\uFE70-\uFEFF]/g, '[ARABIC]') // Arabic Presentation Forms-B
      .replace(/[\uFF00-\uFFEF]/g, '[HALFWIDTH]') // Halfwidth and Fullwidth Forms
      .replace(/[\uFFF0-\uFFFF]/g, '[SPECIAL]'); // Specials    
    return filteredText;
  };

  const handleSubmitScript = async () => {
    if (textareaContent.trim()) {
      console.log('before processing convert', textareaContent);
      await uploadTextAsPDF(textareaContent);
      setShowScriptPrompt(false);
    } else {
      alert('Please paste some information before submitting.');
    }
  };

  const uploadFile = async (file: File) => {
    const fileName = `${userUID}_${file.name}.wav`; // Convert the file to WAV format
    const storageRef = ref(storage, `recordings/${fileName}`);
  
    try {
      const userFilesRef = ref(storage, `recordings/`);
      const fileList = await listAll(userFilesRef);
  
      if (isAnonymous) {
        const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
        if (existingFiles.length >= 2) {
          alert('Anonymous users can only upload up to two files. Registering as a premium user will allow unlimited file uploads.');
          return;
        }
      } else {
        const firestore = getFirestore();
        const userDocRef = doc(firestore, 'users', userUID);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
  
        const isStarterUser = userData?.subscriptionStatus !== true;
        if (isStarterUser) {
          const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
          if (existingFiles.length >= 2) {
            alert('Starter users can only upload up to two files.');
            return;
          }
        }
      }
  
      // Step 1: Convert the audio file to ArrayBuffer and decode it
      const arrayBuffer = await file.arrayBuffer();
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  
      // Step 2: Convert the AudioBuffer to WAV format
      const wavBlob = audioBufferToWav(audioBuffer);
  
      // Step 3: Upload the WAV file to Firebase Storage
      await uploadBytes(storageRef, wavBlob);
      console.log('WAV file uploaded to Firebase Storage.');
  
      // Step 4: Get the download URL of the uploaded file
      const downloadUrl = await getDownloadURL(storageRef);
      console.log('Download URL:', downloadUrl);
  
      // Step 5: Calculate the duration using AudioContext
      const calculateAudioDuration = async (audioUrl: string) => {
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        return audioBuffer.duration;
      };
  
      const duration = await calculateAudioDuration(downloadUrl);
      console.log(`Calculated Duration: ${duration} seconds`);
  
      // Ask the user if they want to share the file with the public
      const shareWithPublic = window.confirm('Do you want to share this file with the public?');
  
      // Set custom metadata based on the user's selection
      const customMetadata = {
        customMetadata: {
          ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
          UserID: userUID,
          DocumentType: 'Recording',
          Duration: duration.toFixed(2), // Save duration as metadata (in seconds)
        },
      };
  
      // Step 6: Update the metadata in Firebase Storage
      await updateMetadata(storageRef, customMetadata);
      fetchRecordings();
      alert('Recording uploaded successfully!');
    } catch (error) {
      console.error('Upload error:', error);
    }
  };

  const uploadTextAsPDF = async (text: string) => {
    const pdfDoc = await PDFDocument.create();
  
    // Register fontkit
    pdfDoc.registerFontkit(fontkit as any);
  
    // Load a custom Unicode font from a TTF file
    const fontUrl = process.env.PUBLIC_URL + '/assets/fonts/Roboto-Regular.ttf';
    const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
    const customFont = await pdfDoc.embedFont(fontBytes); // Embed custom font
  
    // Set up an 8x11 inch page in portrait format (8.5 inches wide, 11 inches tall)
    const pageWidth = 8.5 * 72;
    const pageHeight = 11 * 72;
  
    let page = pdfDoc.addPage([pageWidth, pageHeight]);
    const { width, height } = page.getSize();
    const fontSize = 12;
    const lineHeight = fontSize + 4;
    const margin = 50;
    const maxLineWidth = width - margin * 2;
  
    const sanitizedText = filterUnsupportedCharacters(text);
    const paragraphs = sanitizedText.split('\n');
  
    let y = height - margin;
  
    for (const paragraph of paragraphs) {
      const words = paragraph.split(' ');
      let currentLine = '';
  
      for (const word of words) {
        const testLine = currentLine + word + ' ';
        const testLineWidth = customFont.widthOfTextAtSize(testLine, fontSize);
  
        if (testLineWidth > maxLineWidth) {
          if (y - lineHeight < margin) {
            page = pdfDoc.addPage([pageWidth, pageHeight]);
            y = height - margin;
          }
          page.drawText(currentLine, {
            x: margin,
            y: y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });
          currentLine = word + ' ';
          y -= lineHeight;
        } else {
          currentLine = testLine;
        }
      }
  
      if (currentLine.trim()) {
        if (y - lineHeight < margin) {
          page = pdfDoc.addPage([pageWidth, pageHeight]);
          y = height - margin;
        }
        page.drawText(currentLine.trim(), {
          x: margin,
          y: y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        });
        y -= lineHeight;
      }
  
      // After processing each paragraph, add a line break
      y -= lineHeight;
    }
  
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const fileName = `${userUID}_Note${noteNumber}.pdf`;
    const storageRef = ref(storage, `uploads/${fileName}`);
  
    try {
      const userFilesRef = ref(storage, `uploads/`);
      const fileList = await listAll(userFilesRef);
  
      if (isAnonymous) {
        // Allow anonymous users to upload a maximum of 2 files
        const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
        if (existingFiles.length >= 2) {
          alert('Anonymous users can only upload up to two files.');
          return;
        }
      } else {
        // For non-anonymous users, check Firestore for subscriptionStatus
        const firestore = getFirestore();
        const userDocRef = doc(firestore, 'users', userUID);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
  
        const isStarterUser = userData?.subscriptionStatus !== true; // Starter user if subscriptionStatus is not true
  
        if (isStarterUser) {
          // Check if the starter user has already uploaded 2 files
          const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
          if (existingFiles.length >= 2) {
            alert('Starter users can only upload up to two files.');
            return;
          }
        }
      }
  
      // Ask the user if they want to share the file with the public
      const shareWithPublic = window.confirm('Do you want to share this file with the public?');

      // Set custom metadata based on the user's selection
      const customMetadata = {
        customMetadata: {
          ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
          UserID:userUID,
          DocumentType:'Note',
        },
      };

      await uploadBytes(storageRef, blob,customMetadata);

      alert('PDF submitted successfully!');
  
      const newNoteNumber = noteNumber + 1;
      setNoteNumber(newNoteNumber);
      setTextareaContent('');
  
      if (!isAnonymous) {
        saveNoteNumberToDatabase(userUID, newNoteNumber);
      } else {
        globalNoteNumber = newNoteNumber;
      }
    } catch (error) {
      console.error('Upload error:', error);
    }
  };


 // Start recording audio
 const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.ondataavailable = (event) => {
          setAudioBlob(event.data); // Store audio data
        };

        mediaRecorder.start();
        setRecorder(mediaRecorder);
        setIsRecording(true);
        setRecordingProgress(0);

        // Start the interval to track recording progress
        recordingInterval.current = setInterval(() => {
          setRecordingProgress((prev) => prev + 1);
        }, 1000); // Update every second
      } catch (error) {
        console.error('Error starting audio recording:', error);
      }
    } else {
      alert('Audio recording is not supported in this browser.');
    }
  };

  const stopRecording = () => {
    if (recorder) {
      recorder.stop();
      recorder.stream.getTracks().forEach(track => track.stop()); // Stop the stream
      setRecorder(null); // Release the recorder
      setIsRecording(false);
      
      if (recordingInterval.current) {
        clearInterval(recordingInterval.current);
      }
      
      setShowFileNamePrompt(true); // Show file name prompt after stopping
    }
  };


// Utility to convert an AudioBuffer to WAV format
const audioBufferToWav = (buffer: AudioBuffer) => {
    const numOfChan = buffer.numberOfChannels;
    const length = buffer.length * numOfChan * 2 + 44;
    const bufferData = new ArrayBuffer(length);
    const view = new DataView(bufferData);
  
    // Write WAV header
    let offset = 0;
    const writeString = (s: string) => {
      for (let i = 0; i < s.length; i++) {
        view.setUint8(offset++, s.charCodeAt(i));
      }
    };
    const setUint16 = (data: number) => {
      view.setUint16(offset, data, true);
      offset += 2;
    };
    const setUint32 = (data: number) => {
      view.setUint32(offset, data, true);
      offset += 4;
    };
  
    // RIFF chunk descriptor
    writeString('RIFF');
    setUint32(length - 8);
    writeString('WAVE');
  
    // fmt sub-chunk
    writeString('fmt ');
    setUint32(16);
    setUint16(1);
    setUint16(numOfChan);
    setUint32(buffer.sampleRate);
    setUint32(buffer.sampleRate * 2 * numOfChan);
    setUint16(numOfChan * 2);
    setUint16(16);
  
    // data sub-chunk
    writeString('data');
    setUint32(length - offset - 4);
  
    // Write interleaved audio data
    const channels = [];
    for (let i = 0; i < numOfChan; i++) {
      channels.push(buffer.getChannelData(i));
    }
  
    let sample;
    for (let i = 0; i < buffer.length; i++) {
      for (let j = 0; j < numOfChan; j++) {
        sample = Math.max(-1, Math.min(1, channels[j][i]));
        sample = sample < 0 ? sample * 0x8000 : sample * 0x7FFF;
        view.setInt16(offset, sample, true);
        offset += 2;
      }
    }
  
    return new Blob([bufferData], { type: 'audio/wav' });
  };
  
  // Example usage in your uploadRecording function
  const uploadRecording = async () => {
    if (!audioBlob || !customFileName.trim()) return;
  
    const fileName = `${userUID}_${customFileName.trim()}.wav`; // Convert to WAV format
    const storageRef = ref(storage, `recordings/${fileName}`);
  
    try {
      // Step 1: Convert the audioBlob to ArrayBuffer
      const arrayBuffer = await audioBlob.arrayBuffer();
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  
      // Step 2: Convert the AudioBuffer to WAV Blob
      const wavBlob = audioBufferToWav(audioBuffer);
  
      // Step 3: Upload the WAV file to Firebase Storage
      await uploadBytes(storageRef, wavBlob);
      console.log('Converted WAV file uploaded to Firebase Storage.');
  
      // Step 4: Get the download URL of the uploaded file
      const downloadUrl = await getDownloadURL(storageRef);
      console.log('Download URL:', downloadUrl);
  
    // Step 5: Calculate the duration using AudioContext
     const calculateAudioDuration = async (audioUrl: string) => {
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        return audioBuffer.duration; // Get the duration from the AudioBuffer
      };
  
      const duration = await calculateAudioDuration(downloadUrl);
      console.log(`Calculated Duration: ${duration} seconds`);
  
      // Ask the user if they want to share the file with the public
      const shareWithPublic = window.confirm('Do you want to share this file with the public?');
  
      // Step 6: Update the metadata with the calculated duration
      const customMetadata = {
        customMetadata: {
          ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
          UserID: userUID,
          DocumentType: 'Recording',
          Duration: duration.toFixed(2), // Save duration as metadata (in seconds)
        },
      };
  
      // Step 7: Update the metadata in Firebase Storage
      await updateMetadata(storageRef, customMetadata);
      fetchRecordings(); 
      alert('Recording uploaded successfully!');

      // Step 5: Reset UI
      setAudioBlob(null);
      setCustomFileName('');
      setShowFileNamePrompt(false); // Hide the prompt

    } catch (error) {
      console.error('Error uploading recording:', error);
      alert('An error occurred while uploading the recording.');
    }
  };
  
    // Cancel file upload and close the prompt
    const handleCancel = () => {
        setCustomFileName('');
        setShowFileNamePrompt(false);
        setAudioBlob(null); 
      };

    const handlePlayAudio = async (url: string, fileRefPath: string) => {
        if (audioRef.current) {
          try {
            // Step 1: Fetch metadata for the file
            const storageRef = ref(storage, fileRefPath);
            const metadata = await getMetadata(storageRef);
            
            // Step 2: Check if the metadata contains the duration
            const duration = metadata.customMetadata?.Duration;
            if (duration) {
              setMetadataDuration(parseFloat(duration)); // Store the duration from metadata
            } else {
              setMetadataDuration(null); // If no duration in metadata, fallback to null
            }
      
            // Step 3: Handle play/pause logic
            if (playingAudio === url && isPlaying) {
              audioRef.current.pause();
              setIsPlaying(false);
            } else {
              audioRef.current.src = url;
              audioRef.current.currentTime = 0;
      
              // Log the actual duration once the audio metadata is loaded
              audioRef.current.onloadedmetadata = () => {
                if (!metadataDuration) {
                  console.log(`Actual Duration from audio element: ${audioRef.current?.duration} seconds`);
                }
              };
      
              audioRef.current.play();
              setPlayingAudio(url);
              setIsPlaying(true);
            }
          } catch (error) {
            console.error('Error fetching metadata or playing audio:', error);
            alert('An error occurred while trying to play the audio.');
          }
        }
      };

    const handleTranscribeAudio = async (url: string) => {
        console.log('Transcribing audio for', url);
        setShowScriptPrompt(true);
        try {
          setTextareaContent('Processing the audio, please wait...');
          setIsConverting(true);
      
          // Step 1: Fetch the audio file from the provided URL
          const audioBlob = await fetch(url).then((res) => res.blob());
      
          // Step 2: Convert the audio Blob to base64 format (if necessary, Whisper API can accept Blob directly)
          const audioFile = new File([audioBlob], "audio.mp4", { type: audioBlob.type });
          console.log("audio file type:", audioBlob.type)
          // Step 3: Call OpenAI's Whisper API for transcription
          const transcriptionText = await transcribeAudioWithOpenAI(audioFile);   
          setTextareaContent(transcriptionText); // Update the textarea with the transcription
        } catch (error) {
          setTextareaContent('An error occurred during audio transcription. Please try again.');
          console.error('Error during transcription:', error);
        } finally {
          setIsConverting(false);
        }
      };
      
      const transcribeAudioWithOpenAI = async (audioFile: File): Promise<string> => {
        try {
          // Call OpenAI's Whisper API for transcription
          const transcription = await openai.audio.transcriptions.create({
            file: audioFile,
            model: "whisper-1",
            response_format: "text",
          });
      
          console.log('transcription', transcription);
      
          // Convert the transcription to a string explicitly
          if (transcription && typeof transcription === 'string') {
            return transcription; // Return the transcription as a string
          } else if (transcription && transcription.text) {
            return String(transcription.text); // Safely convert the text to a string
          } else {
            throw new Error("No transcription text found in the response.");
          }
        } catch (error) {
          console.error("Error during transcription:", error);
          return "Error during transcription.";
        }
      };
      
      const handleRenameFile = async (fileRefPath: string) => {
        const newName = prompt('Enter a new name for the file:');
        if (!newName) return; // Exit if no name is entered
        
        const currentUser = auth.currentUser;
        if (!currentUser) return; // Ensure the user is authenticated
        
        const userUID = currentUser.uid;
        const newFileRefPath = `recordings/${userUID}_${newName}.webm`; // Create new file path with new name
        const fileRef = ref(storage, fileRefPath);
        const newFileRef = ref(storage, newFileRefPath);
      
        try {
          // Fetch the file from Firebase Storage
          const fileUrl = await getDownloadURL(fileRef);
          const response = await fetch(fileUrl);
          const blob = await response.blob();
      
          // Get custom metadata from the original file
          const metadata = await getMetadata(fileRef); // Use getMetadata function here
          const customMetadata = metadata.customMetadata;
      
          // Upload the file with the new name and carry over the custom metadata
          await uploadBytes(newFileRef, blob, { customMetadata });
    
      
          // Delete the original file
          await deleteObject(fileRef);
      
          fetchRecordings(); 
          alert('File renamed successfully.');
        } catch (error) {
          console.error('Error renaming file:', error);
          alert('Failed to rename the file. Please try again.');
        }
      };

    const handleDeleteFile = async (fileRefPath: string) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this file?');
    if (confirmDelete) {
        const fileRef = ref(storage, fileRefPath);
        await deleteObject(fileRef);
        setRecordings((prev) => prev.filter((file) => file.refPath !== fileRefPath));
        alert('File deleted successfully!');
    }
    };    

    const toggleMenu = (index: number) => {
        setOpenMenuIndex(openMenuIndex === index ? null : index);
      };

  // Function to update progress of audio playback
const handleAudioProgress = () => {
  if (audioRef.current) {
    const currentTime = audioRef.current.currentTime;

    // Use metadataDuration if available, otherwise fallback to audioRef.current.duration
    const duration = metadataDuration || audioRef.current.duration;

    if (isFinite(currentTime) && isFinite(duration) && duration > 0) {
      const progress = (currentTime / duration) * 100;
      setAudioProgress(progress);
    }
  }
};
// Function to handle seeking when dragging the progress bar
const handleAudioSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (audioRef.current && isFinite(audioRef.current.duration)) {
      const seekTime = (parseFloat(event.target.value) / 100) * audioRef.current.duration;
      if (isFinite(seekTime)) {
        audioRef.current.currentTime = seekTime;
      }
    }
  };
  
  // Function to handle metadata load (duration availability)
  const handleMetadataLoaded = () => {
    if (audioRef.current && isFinite(audioRef.current.duration)) {
      setAudioProgress(0); // Reset progress when metadata is loaded
    }
  };
  
  
  const handleFastForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 10; // Fast forward 10 seconds
    }
  };

  const handleRewind = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 10; // Rewind 10 seconds
    }
  };

  const handleSpeedChange = () => {
    if (audioRef.current) {
      const newSpeed = playbackSpeed === 1.0 ? 1.5 : playbackSpeed === 1.5 ? 2.0 : 1.0;
      audioRef.current.playbackRate = newSpeed;
      setPlaybackSpeed(newSpeed); // Update playback speed
    }
  };

  const closeAudioBar = () => {
    if (audioRef.current) {
      audioRef.current.pause(); // Stop the audio
      audioRef.current.currentTime = 0; // Reset the audio position
    }
    setPlayingAudio(null); // Close the audio bar
    setIsPlaying(false);
    setAudioProgress(0);
  };
  

  const handleHomeClick = async () => {
    // Your logic to handle the home click, e.g., navigate to the home page or fetch data
    navigate('/home'); // Example navigation action
  };

  return (
    <div className="container">
      <NavBar 
        userImage={userImage} 
        onProfileLoad={handleProfileLoad} 
      />
      <div className="main-content">
      <Sidebar onLogout={handleLogout} onHomeClick={handleHomeClick} />
        <div className="content">
          <div className="content-inner">
              <div 
                className={`audio-file-drop-area ${isDragging ? 'dragging' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                 >
                <p style={{ color: 'white', marginBottom: '0px',marginTop: '10px' }}>Drop your Audio files here or</p> 
                <input 
                type="file" 
                onChange={handleFileChange} 
                style={{ display: 'none' }} 
                id="fileInput"
                />
                
                <label htmlFor="fileInput" className="upload-button" style={{ marginTop: '5px' }}> {/* Added marginTop to move it upward */}
                Choose files
                </label>
               </div>

                {/* Recording Container */}
                <div className="recording-container" style={{ marginTop: '20px', textAlign: 'center' }}>
                {isRecording ? (
                    <>
                    <p style={{ color: 'white', marginBottom: '10px' }}>Recording... {new Date(recordingProgress * 1000).toISOString().slice(11, 19)}</p> {/* Shows time dynamically */}
                    <button onClick={stopRecording} style={{ backgroundColor: 'red', color: 'white', padding: '10px 20px' }}>
                        Stop Recording
                    </button>
                    </>
                ) : (
                    <button onClick={startRecording} style={{ backgroundColor: 'green', color: 'white', padding: '10px 20px',marginBottom: '10px' }}>
                    Start Recording
                    </button>
                )}
                                
                {!isRecording && showFileNamePrompt && (
                <div className="modal-overlay">
                    <div className="modal-content qwindow-modal">
                    <div className="file-name-prompt" style={{ marginTop: '20px', textAlign: 'center' }}>
                        <label htmlFor="fileName" style={{ color: '#f4f4f9', fontSize: '18px', marginBottom: '10px', display: 'block' }}>
                        Enter file name for your recording:
                        </label>
                        <input
                        type="text"
                        id="fileName"
                        value={customFileName}
                        onChange={(e) => setCustomFileName(e.target.value)}
                        placeholder="File name"
                        style={{
                            padding: '10px',
                            width: '100%',
                            maxWidth: '400px',
                            borderRadius: '8px',
                            border: '1px solid #ddd',
                            marginBottom: '20px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            fontSize: '16px',
                        }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
                        <button
                            onClick={uploadRecording}
                             className="ok-button qwindow-button"
                            disabled={!customFileName.trim()}
                        >
                            Upload Recording
                        </button>
                        <button
                            onClick={handleCancel}
                            className="cancel-button qwindow-button"
                        >
                            Cancel
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
                )}

                </div>

                {/* Recording List */}
                <div className="short-file-list-container">
                <ul className="file-list">
                    {recordings.map((file, index) => {
                    // Remove the user UID from the file name by splitting at the first underscore
                    const fileNameWithoutUID = file.name.split('_').slice(1).join('_');

                    return (
                        <li key={index} className="audio-file-list-item">
                        <div className="file-items"> 
                            <span className="file-name">{fileNameWithoutUID}</span>
                            <div className="file-actions">
                                <div className="button-grid-container-public">
                                <button
                                    title="Play"
                                    onClick={() => handlePlayAudio(file.url, file.refPath)}
                                    className="view-button"
                                >
                                    <FaPlay size={18} />
                                </button>
                                <button
                                    title="Transcribe"
                                    className="information-button"
                                    onClick={() => handleTranscribeAudio(file.url)}
                                >
                                    <FaFileAlt size={18} />
                                </button>
                                </div>
                                <div className="menu-container">
                                <button
                                    title="Tools"
                                    className="menu-button"
                                    onClick={() => toggleMenu(index)}
                                >
                                    ...
                                </button>
                                {openMenuIndex === index && (
                                    <div className="menu-content">
                                    <button
                                        className="menu-option"
                                        onClick={() => handleRenameFile(file.refPath)}
                                    >
                                        <FaPen size={18} style={{ marginRight: '8px' }} />
                                        Rename
                                    </button>
                                    <button
                                        className="menu-option"
                                        onClick={() => handleDeleteFile(file.refPath)}
                                    >
                                        <FaTrash size={18} style={{ marginRight: '8px' }} />
                                        Delete
                                    </button>
                                    </div>
                                )}
                                </div>
                            </div>
                          </div>    

                          {playingAudio === file.url && (
                            
                          <div className="audio-play-bar">
                            <div className="audio-controls">
                                <button onClick={handleRewind}><FaBackward size={18} /></button>
                                <button onClick={handlePlayAudio.bind(null, file.url, file.refPath)}>
                                {isPlaying ? <FaPause size={18} /> : <FaPlay size={18} />}
                                </button>
                                <button onClick={handleFastForward}><FaForward size={18} /></button>
                                <button onClick={handleSpeedChange}>
                                {`${playbackSpeed}x`}
                                </button>
                                <button onClick={closeAudioBar}><FaTimes size={18} /></button>
                            </div>
                            <div className="audio-progress-container">
                                <input
                                type="range"
                                min="0"
                                max="100"
                                value={isNaN(audioProgress) ? 0 : audioProgress}
                                onChange={handleAudioSeek} 
                                className="audio-progress-bar"
                                />
                            </div>
                            </div>
                        )}
                        </li>
                    );
                    })}
                </ul>
                </div>

                {showScriptPrompt && (
                <div className="modal-overlay">
                    <div className="modal-content qwindow-modal">
                    <div className="textarea-container" style={{ position: 'relative' }}>
                        <textarea
                        value={textareaContent}
                        onChange={(e) => setTextareaContent(e.target.value)}
                        placeholder="Paste Information Here"
                        className="textarea-input"
                        readOnly={isConverting}
                        ref={textareaRef} // Attach ref to textarea
                        />
                    </div>
                    
                    {/* Button container to hold both Submit and Cancel buttons */}
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <button
                        onClick={() => setShowScriptPrompt(false)} // Close the modal on Cancel
                        className="cancel-button"
                        style={{ backgroundColor: 'gray', color: 'white', padding: '10px 20px' }}
                        >
                        Cancel
                        </button>
                        <button
                        onClick={handleSubmitScript}
                        className="ok-button qwindow-button"
                        >
                        Submit
                        </button>
                    </div>
                    </div>
                </div>
                )}

          </div>          
        </div>
      </div>
      <audio
      ref={audioRef} // Reference to control the audio element
      onTimeUpdate={handleAudioProgress} // Update the progress bar as the audio plays
      onLoadedMetadata={handleMetadataLoaded} // Reset progress bar when metadata (like duration) is loaded
      onEnded={() => setPlayingAudio(null)} // Reset when audio finishes
      />
    </div>
  );
};

export default Recordings;