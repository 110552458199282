import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { signOut } from 'firebase/auth';
import NavBar from './NavBar';
import Sidebar from './Sidebar';
import { FaUserPlus, FaUserMinus, FaUser, FaPaperPlane, FaCopy, FaCheck} from 'react-icons/fa'; // Import icons
import { MdChat } from 'react-icons/md'; // Import icons
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import './Base.css';
import OpenAI from "openai";
import { getDoc,doc, updateDoc, arrayUnion, getFirestore} from 'firebase/firestore';

// OpenAI API setup
const openai = new OpenAI({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
    dangerouslyAllowBrowser: true,
  })

  interface ChatMessage {
    message: string;
    from: 'user' | 'assistant';
    type?: 'text' | 'image'; // Optional property for different message types
  }
  

interface Assistant {
    name: string;
    id: string;
    chat: ChatMessage[];
    virtualId: string;
    instructions?: string;
    model:string,
    tool:string;
    vectorStore:string; 
    threadID: string; 
  }

type AssistantTool =
  | { type: 'code_interpreter'; languages?: string[] }  // Making 'languages' optional
  | { type: 'file_search' }
  | { type: 'function'; function: FunctionDefinition };

  type FunctionParameters = {
    type: "object";
    properties: Record<string, { type: string; description: string; enum?: string[] }>;
    required: string[];
  };

  interface FunctionDefinition {
    name: string;
    description: string;
    parameters: FunctionParameters;
  }

const Chat: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [currentAssistantIndex, setCurrentAssistantIndex] = useState<number | null>(null);
  const [newMessage, setNewMessage] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null); // Track hover state
  const [showAssistantWindow, setShowAssistantWindow] = useState(false); // To toggle assistant creation window
  const [assistantName, setAssistantName] = useState(''); // Assistant name
  const [assistantInstructions, setAssistantInstructions] = useState(''); // Assistant instructions
  const [assistantTool] = useState('file_search');
  const [showUpdateAssistantWindow, setShowUpdateAssistantWindow] = useState(false);
  const [isRemoveAssistantOpen, setIsRemoveAssistantOpen] = useState(false); // New state for remove assistant window
  const [selectedAssistants, setSelectedAssistants] = useState<string[]>([]); // Store selected assistants for removal
  const [isFullScreen, setIsFullScreen] = useState(false); // Track full-screen mode
  const [copiedKey, setCopiedKey] = useState<number | null>(null); // Track which code snippet is copied
  const [imagePreviewUrls, setImagePreviewUrls] = useState<string[]>([]); 
  const [loading, setLoading] = useState(false);

  const handleCopy = (key: number) => {
    setCopiedKey(key);
    setTimeout(() => {
      setCopiedKey(null); // Reset after 2 seconds
    }, 2000);
  };
  
  const db = getFirestore();

  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  
    // Fetch assistants and currentAssistantIndex from Firestore when the component loads
    const fetchAssistantsFromFirestore = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
  
          // Fetch assistants
          if (userData.assistants) {
            const fetchedAssistants = userData.assistants.map((assistant: any) => ({
              name: assistant.name,
              id: assistant.id,
              chat: assistant.lastChatMessages || [], // Fetch the last set of chat messages
              virtualId: assistant.name.charAt(0).toUpperCase(),
              instructions: assistant.instructions,
              tool: assistant.tool,
              model: assistant.model,
              vectorStore: assistant.vectorStoreId,
              threadID: assistant.threadID,
            }));
  
            setAssistants(fetchedAssistants);
            console.log('Fetched assistants:', fetchedAssistants);
          }
  
          // Fetch currentAssistantIndex
          if (userData.currentAssistantIndex !== undefined) {
            setCurrentAssistantIndex(userData.currentAssistantIndex);
            console.log('Fetched currentAssistantIndex:', userData.currentAssistantIndex);
          } else {
            console.log('No currentAssistantIndex found.');
          }
        } else {
          console.error('No such document!');
        }
      }
    };
  
    fetchAssistantsFromFirestore();
  
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [db]);
  
  

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadBio: string) => {
    setUserImage(loadedImageUrl);
  };

  const createAssistant = () => {
    setShowAssistantWindow(true); // Open the assistant creation window
  };

  const newChat = async () => {
    if (currentAssistantIndex === null) {
      alert("No assistant selected to start a new chat.");
      return;
    }
  
    const assistant = assistants[currentAssistantIndex];
  
    try {
      // Reset the chat in the state
      const updatedAssistants = assistants.map((asst, index) => {
        if (index === currentAssistantIndex) {
          return { ...asst, chat: [] };
        }
        return asst;
      });
  
      setAssistants(updatedAssistants);
  
      // Clear lastChatMessages in Firestore for the selected assistant
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const assistantsData = userData.assistants;
  
          const updatedAssistantsData = assistantsData.map((asst: any) => {
            if (asst.id === assistant.id) {
              return { ...asst, lastChatMessages: [] }; // Clear chat messages in Firestore
            }
            return asst;
          });
  
          await updateDoc(userDocRef, { assistants: updatedAssistantsData });
          console.log("Chat messages cleared in Firestore for assistant ID:", assistant.id);
        }
      }
    } catch (error) {
      console.error("Error clearing chat:", error);
      alert("Failed to clear the chat. Please try again.");
    }
  };
  
  const handleCreateAssistant = async () => {
    // Ensure assistantName is always a valid string
    const safeAssistantName = assistantName?.trim() || `Assistant ${assistants.length + 1}`;
  
    if (!safeAssistantName || !assistantInstructions) {
      alert('Please fill in all required fields.');
      return;
    }
  
    try {
      console.log('Creating Assistant with:', { assistantName: safeAssistantName, assistantInstructions });
  
      let tools: AssistantTool[];
      if (assistantTool === 'function') {
        tools = [{
          type: "function",
          function: {
            name: "exampleFunction",
            description: "Describe the function here",
            parameters: {
              type: "object",
              properties: {
                exampleParam: {
                  type: "string",
                  description: "An example parameter",
                },
              },
              required: ["exampleParam"],
            },
          },
        }];
      } else if (assistantTool === 'code_interpreter') {
        tools = [{ type: "code_interpreter" }];
      } else {
        tools = [{ type: "file_search" }];
      }
  
      // Call OpenAI API to create the assistant
      const response = await openai.beta.assistants.create({
        name: safeAssistantName,
        instructions: assistantInstructions,
        model: "gpt-4o-mini",
        tools: [{ type: "file_search" }],
        metadata: { username: 'your-username' },
      });


      console.log('Assistant created:', response);
  
      let vector_store = '';  
      if (tools[0].type === 'file_search') {
        const vectorStore = await openai.beta.vectorStores.create({
          name: safeAssistantName + '_vectorStore',
        });
  
        console.log('Vector store created:', vectorStore);
  
        await openai.beta.assistants.update(response.id, {
          tool_resources: { file_search: { vector_store_ids: [vectorStore.id] } },
        });
  
        vector_store = vectorStore.id;
      }
  
      // No longer saving virtualId to Firestore, we calculate it from the first letter of the name
      const virtualId = safeAssistantName.charAt(0).toUpperCase();

        // Create a new thread
        const threadResponse = await openai.beta.threads.create();
        // threadResponse will contain the ID of the created thread
        const threadID = threadResponse.id;

      // Add the new assistant to the state
      const newAssistant: Assistant = {
        name: safeAssistantName,
        id: response.id, // Use numeric value for id
        chat: [],
        virtualId, // Assign virtualId based on the first letter of the name
        tool:'file_search',
        model:'gpt-4o-mini',
        vectorStore:vector_store,
        threadID: threadID, 
      };
  
      // Save the assistant information to Firestore under the current user profile
      const user = auth.currentUser;
      const db = getFirestore();
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
  
        // Create the assistant information JSON object
        const assistantData = {
          name: safeAssistantName,
          id: newAssistant.id,
          instructions: assistantInstructions,
          model: 'gpt-4o-mini',
          vectorStoreId: vector_store,
          tool: tools[0].type,
          threadID: threadID, 
        };
  
        // Add the new assistant data to the "assistants" array in Firestore
        await updateDoc(userDocRef, {
          assistants: arrayUnion(assistantData),
        });
  
        console.log('Assistant information saved to Firestore.');
      } else {
        console.error('No authenticated user found.');
      }
  
      setAssistants([...assistants, newAssistant]);
      setShowAssistantWindow(false); // Close the assistant creation window
      setAssistantName(''); // Reset input fields
      setAssistantInstructions('');
  
      alert('Assistant created and saved successfully.');
    } catch (error) {
      console.error('Failed to create assistant:', error);
      alert('Failed to create the assistant. Please try again.');
    }
  };
  
  const closeAssistantWindow = () => {
    setShowAssistantWindow(false); // Close the window without creating an assistant
  };

  const handleUpdateAssistant = async () => {
    if (currentAssistantIndex === null) {
      alert("No assistant selected for updating.");
      return;
    }
  
    // Ensure assistantName is always a valid string
    const safeAssistantName = assistantName?.trim() || `Assistant ${assistants[currentAssistantIndex]?.name}`;
  
    if (!safeAssistantName || !assistantInstructions) {
      alert('Please fill in all required fields.');
      return;
    }
  
    try {
      console.log('Updating Assistant with:', { assistantName: safeAssistantName, assistantInstructions });
  
      let tools: AssistantTool[];
      if (assistantTool === 'function') {
        tools = [{
          type: "function",
          function: {
            name: "exampleFunction",
            description: "Describe the function here",
            parameters: {
              type: "object",
              properties: {
                exampleParam: {
                  type: "string",
                  description: "An example parameter",
                },
              },
              required: ["exampleParam"],
            },
          },
        }];
      } else if (assistantTool === 'code_interpreter') {
        tools = [{ type: "code_interpreter" }];
      } else {
        tools = [{ type: "file_search" }];
      }
  
      const currentAssistantId = assistants[currentAssistantIndex]?.id.toString(); // Convert number to string
  
      // Call OpenAI API to update the assistant
      const updatedAssistant = await openai.beta.assistants.update(currentAssistantId, {
        name: safeAssistantName,
        instructions: assistantInstructions,
        model: 'gpt-4o-mini', // Specify the model (adjust as per requirements)
        tools,
      });
  
      console.log('Assistant updated:', updatedAssistant);
  
      // Calculate the new virtualId based on the updated name
      const newVirtualId = safeAssistantName.charAt(0).toUpperCase();
  
      // Save the updated assistant information to Firestore
      const user = auth.currentUser;
      const db = getFirestore();
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
  
        let updatedAssistants = [];
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const existingAssistants = userData.assistants || [];
  
          // Check if an assistant with the same id already exists
          const assistantExists = existingAssistants.some((assistant: any) => assistant.id === currentAssistantId);
  
          if (assistantExists) {
            // Replace the existing assistant's information
            updatedAssistants = existingAssistants.map((assistant: any) =>
              assistant.id === currentAssistantId
                ? {
                    ...assistant,
                    name: safeAssistantName,
                    instructions: assistantInstructions,
                    model: 'gpt-4o',
                    tool: tools[0].type,
                  }
                : assistant
            );
          } else {
            // Add the new assistant data to the list
            updatedAssistants = [...existingAssistants, {
              name: safeAssistantName,
              id: currentAssistantId,
              instructions: assistantInstructions,
              model: 'gpt-4o',
              tool: tools[0].type,
            }];
          }
        }
  
        // Update the assistants array in Firestore
        await updateDoc(userDocRef, {
          assistants: updatedAssistants,
        });
  
        console.log('Assistant information updated in Firestore.');
  
        // Update the assistant in the state
        setAssistants(prevAssistants =>
          prevAssistants.map((assistant, index) =>
            index === currentAssistantIndex
              ? { ...assistant, name: safeAssistantName, instructions: assistantInstructions, virtualId: newVirtualId }
              : assistant
          )
        );
  
        setShowUpdateAssistantWindow(false); // Close the update window
        setAssistantName(''); // Reset input fields
        setAssistantInstructions('');
  
        alert('Assistant updated successfully.');
      } else {
        console.error('No authenticated user found.');
      }
    } catch (error) {
      console.error('Failed to update assistant:', error);
      alert('Failed to update the assistant. Please try again.');
    }
  };

// Handle the logic to remove selected assistants
const handleRemoveSelectedAssistants = async () => {
    if (selectedAssistants.length === 0) return;
  
    // Show a warning message before proceeding with removal
    const confirmation = window.confirm(
      `Are you sure you want to remove the selected assistants? This action cannot be undone.`
    );
  
    if (!confirmation) {
      // If the user cancels, exit the function
      console.log('User canceled the removal.');
      return;
    }
  
    try {
      for (const assistantId of selectedAssistants) {
        const assistant = assistants.find(asst => asst.id === assistantId);
        if (!assistant) continue;
  
        // Delete assistant from OpenAI
        await openai.beta.assistants.del(assistantId);
        console.log(`Deleted assistant ${assistant.name} from OpenAI`);
  
        // If the assistant has a file search tool, delete associated vector store files
        if (assistant.tool === 'file_search') {
          const deletedVectorStore = await openai.beta.vectorStores.del(assistant.vectorStore);
          console.log(`Deleted vector store for assistant ${assistant.name}`, deletedVectorStore);
        }
  
        // Remove assistant from Firestore
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const updatedAssistants = userData.assistants.filter((asst: any) => asst.id !== assistantId);
            await updateDoc(userDocRef, { assistants: updatedAssistants });
            console.log(`Removed assistant ${assistant.name} from Firestore`);
          }
        }
      }
  
      // Update state to remove assistants from UI
      setAssistants(prevAssistants => prevAssistants.filter(asst => !selectedAssistants.includes(asst.id)));
      setSelectedAssistants([]); // Clear selection
      setIsRemoveAssistantOpen(false); // Close the modal
  
      alert('Selected assistants removed successfully.');
    } catch (error) {
      console.error('Failed to remove assistants:', error);
      alert('Failed to remove assistants. Please try again.');
    }
  };
  
  // Handle toggling assistant selection
  const handleToggleAssistantSelection = (assistantId: string) => {
    setSelectedAssistants(prevSelected =>
      prevSelected.includes(assistantId)
        ? prevSelected.filter(id => id !== assistantId) // Unselect assistant
        : [...prevSelected, assistantId] // Select assistant
    );
  };

  const selectAssistant = async (index: number) => {
    setCurrentAssistantIndex(index); // Set the selected assistant index in state
  
    try {
      const user = auth.currentUser;
      if (user) {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          // Overwrite the currentAssistantIndex in Firestore
          await updateDoc(userDocRef, { currentAssistantIndex: index });
          console.log('currentAssistantIndex updated in Firestore:', index);
        } else {
          console.error('User document does not exist.');
        }
      } else {
        console.error('No authenticated user found.');
      }
    } catch (error) {
      console.error('Error updating currentAssistantIndex in Firestore:', error);
    }
  };
  

  const handleAssistantClick = (index: number) => {
    let clickTimeout: NodeJS.Timeout;
  
    const singleClick = () => {
      selectAssistant(index); // Handle single click by selecting the assistant
    };
  
    const doubleClick = () => {
      selectAssistant(index);
      const selectedAssistant = assistants[index];
      if (selectedAssistant) {
        setAssistantName(selectedAssistant.name);
        setAssistantInstructions(selectedAssistant.instructions || ''); // Correctly set instructions as default text
        setShowUpdateAssistantWindow(true); // Show the update window
      }
    };
  
    return (e: React.MouseEvent) => {
      if (e.detail === 1) {
        clickTimeout = setTimeout(singleClick, 200); // Single click handler after a delay
      } else if (e.detail === 2) {
        clearTimeout(clickTimeout); // Cancel single-click action on double-click
        doubleClick(); // Handle double-click
      }
    };
  };
  
  const stopStreaming = () => {
    setLoading(false); // Stop streaming
  };

  const uploadFileToOpenAI = async (file: File) => {
    const response = await openai.files.create({
        file,
        purpose: 'assistants',
      });
    return response.id;
  };

// Function to add a message and multiple images to a thread
  const  addAssistantImageMessage = async (threadId: string, message: string, fileIds: string[]): Promise<any> => {
        console.log('Adding a new message and images to thread: ' + threadId);
  
        // Construct the content array with the message text and image files
        const content = [
          { type: "text", text: message } as const,
          ...fileIds.map(fileId => ({
            type: "image_file",
            image_file: { file_id: fileId }
          } as const))
        ];
  
        // Create the message in the thread
        const response = await openai.beta.threads.messages.create(
          threadId,
          {
            role: "user",
            content: content
          }
        );
  
        return response;
      }
  
  // Helper function to process the assistant's response and update the chat
  const processAssistantResponse = async (assistant: Assistant, updatedChat: ChatMessage[]) => {
    try {
      if (!assistant.threadID) {
        console.error('No thread ID for this assistant.');
        return;
      }
      const threadId = assistant.threadID;
      // Run assistant and get the response
      const runResponse = await openai.beta.threads.runs.create(threadId, {
        assistant_id: assistant.id,
      });

      let isComplete = false;
      while (!isComplete) {
        const runStatus = await openai.beta.threads.runs.retrieve(threadId, runResponse.id);
        if (runStatus.status === 'completed') {
          isComplete = true;
          const messages = await openai.beta.threads.messages.list(threadId);

          if (messages.data.length > 0 && messages.data[0].content.length > 0 && messages.data[0].content[0].type === 'text') {
            const assistantReply = messages.data[0].content[0].text.value;

            // Add assistant's response to chat
            const newUpdatedChat: ChatMessage[] = [...updatedChat, { message: assistantReply, from: 'assistant' }];

            // Update chat state with assistant's message
            setAssistants((prevAssistants) => {
              const updatedAssistants = [...prevAssistants];
              updatedAssistants[currentAssistantIndex!] = {
                ...updatedAssistants[currentAssistantIndex!],
                chat: newUpdatedChat,
              };
              return updatedAssistants;
            });

            // Save both user and assistant chat to Firestore after assistant's response
            await saveChatToFirestore(assistant.id, newUpdatedChat);
            setLoading(false); // Set loading to false after completion
          }
        } else {
          await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds before checking again
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
      setLoading(false); // Ensure loading is reset in case of error
    }
  };
  
// Helper function to save chat to Firestore (limit to 10 messages)
const saveChatToFirestore = async (assistantId: string, chat: ChatMessage[]) => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const assistantsData = userData.assistants;
  
        // Limit the chat to the last 10 messages
        const limitedChat = chat.slice(-10);
  
        // Update the specific assistant's lastChatMessages with a max of 10 messages
        const updatedAssistants = assistantsData.map((asst: any) => {
          if (asst.id === assistantId) {
            return { ...asst, lastChatMessages: limitedChat };
          }
          return asst;
        });
  
        await updateDoc(userDocRef, { assistants: updatedAssistants });
        console.log('Last 10 chat messages updated in Firestore for assistant ID:', assistantId);
      }
    }
  };
  
  const handleSendMessage = async () => {
    if (newMessage.trim() === '' && uploadedFiles.length === 0) return; // Ensure there's either text or an uploaded image

    const assistant = assistants[currentAssistantIndex!];
    let updatedChat: ChatMessage[] = [...assistant.chat];
    let fileQuestion = newMessage;

    // Upload all files and get their URLs and IDs
    const filePromises = uploadedFiles.map(async (file) => {
    const fileUrl = URL.createObjectURL(file);
    console.log("Uploaded file for GPT-4 Vision:", file);

    // Create image tags for the prompt
    fileQuestion = `<img src="${fileUrl}" alt="Uploaded file" style="max-width:100%; height: auto;">\n\n` + fileQuestion;

    // Upload file to OpenAI
    const uploadedFileId = await uploadFileToOpenAI(file);
    return { fileUrl, fileId: uploadedFileId };
    });   

    if (uploadedFiles && uploadedFiles.length > 0) {  
        try {
            const fileData = await Promise.all(filePromises);
            const fileIds = fileData.map(file => file.fileId);

            // Include any text message from the user
            if (fileQuestion.trim() !== '') {
              updatedChat.push({ message: fileQuestion, from: 'user', type: 'text' });
            }

            // Save user's message and uploaded files to Firestore before the assistant responds
            await saveChatToFirestore(assistant.id, updatedChat);
      
            // Update chat state with all messages
            setAssistants((prevAssistants) => {
              const updatedAssistants = [...prevAssistants];
              updatedAssistants[currentAssistantIndex!] = {
                ...updatedAssistants[currentAssistantIndex!],
                chat: updatedChat,
              };
              return updatedAssistants;
            });
      
            setLoading(true); // Set loading to true when model starts running/streaming
      
            // Add a message to the thread with the uploaded file IDs
            const messageResponse = await addAssistantImageMessage(assistant.threadID, fileQuestion, fileIds);
            console.log("Added a message to the thread", messageResponse);
  
            // Process assistant's response after user's message
            await processAssistantResponse(assistant, updatedChat);
      
          } catch (error) {
            console.error('Error uploading files:', error);
            alert('File upload failed. Please try again.');
            return;
          } 

      } else {
        try {
            // Include any text message from the user
            if (newMessage.trim() !== '') {
              updatedChat.push({ message: newMessage, from: 'user', type: 'text' });
            }
      
            // Save user's message and uploaded files to Firestore before the assistant responds
            await saveChatToFirestore(assistant.id, updatedChat);
      
            // Update chat state with all messages
            setAssistants((prevAssistants) => {
              const updatedAssistants = [...prevAssistants];
              updatedAssistants[currentAssistantIndex!] = {
                ...updatedAssistants[currentAssistantIndex!],
                chat: updatedChat,
              };
              return updatedAssistants;
            });
      
            setLoading(true); // Set loading to true when model starts running/streaming

            const content: any[] = [];
            
            // Add the user's text message to the assistant's content
            if (newMessage.trim() !== '') {
                content.push({
                type: 'text',
                text: newMessage,
                });
            }
        
            // Check if the assistant has a thread ID
            if (!assistant.threadID) {
                console.error('No thread ID for this assistant.');
                return;
            }

            const threadId = assistant.threadID;
        
            // Send the message and uploaded images to the assistant
            await openai.beta.threads.messages.create(threadId, {
                role: 'user',
                content: content,
            });
        
            // Process assistant's response after user's message
            await processAssistantResponse(assistant, updatedChat);
      
          } catch (error) {
            console.error('Error uploading files:', error);
            alert('File upload failed. Please try again.');
         }
   }
   setNewMessage(''); // Clear the input after sending
   setUploadedFiles([]); 
   setImagePreviewUrls([]); 
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const filesArray = Array.from(e.target.files); // Convert FileList to an array
      const newFiles = filesArray.map((file) => URL.createObjectURL(file)); // Create URLs for each file
  
      setUploadedFiles((prevFiles) => [...prevFiles, ...filesArray]); // Store multiple files
      setImagePreviewUrls((prevUrls) => [...prevUrls, ...newFiles]);  // Store multiple image URLs for preview
    }
  };

  const splitIntoSegments = (text: string): string[] => {
    return text.split(/\n\n/).map(segment => segment.trim()).filter(segment => segment);
  };
  
  const formatCodeSnippet = (
    segment: string, 
    key: number, 
    copiedKey: number | null, 
    handleCopy: (key: number) => void
  ): JSX.Element => {
    const [, lang = '', code = ''] = segment.match(/^```(.*?)\n([\s\S]*?)```$/) || [];
    
    const header = (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#001f3f', padding: '5px 10px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
        <span>{lang.trim()}</span>
        <button 
          onClick={() => handleCopy(key)} 
          style={{ backgroundColor: 'transparent', color: 'white', border: 'none', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          {copiedKey === key ? <FaCheck /> : <FaCopy />}
          <span style={{ marginLeft: '5px' }}>
            {copiedKey === key ? 'Copied' : 'Copy'}
          </span>
        </button>
      </div>
    );
  
    return (
      <div key={`code-container-${key}`} style={{ backgroundColor: '#002366', padding: '10px', borderRadius: '5px', width: '90%', margin: '10px auto', color: 'white', wordBreak: 'break-word', overflowX: 'auto' }}>
        {header}
        <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{code.trim()}</pre>
      </div>
    );
  };
  
  
  const isCodeSnippet = (segment: string): boolean => {
    return segment.startsWith('```') || segment.endsWith('```');
  };
  
  const isImage = (segment: string): boolean => {
    return segment.startsWith('<img src="') && segment.endsWith('">');
  };
  
  const isAudio = (segment: string): boolean => {
    return segment.startsWith('<audio') && segment.endsWith('</audio>');
  };
  
  const extractListNumber = (segment: string | undefined): number | undefined => {
    if (!segment) {
      return undefined;
    }
  
    // Extracts the starting number from a segment that starts with a number followed by a period (e.g., "1. ", "2. ")
    const match = segment.match(/^(\d+)\.\s+/);
    return match ? parseInt(match[1], 10) : undefined;
  };
  
  const isListInSegment = (segment: string | undefined): boolean => {
    if (!segment) {
      return false;
    }
  
    // Match segments that contain multiple numbered list items (e.g., "1. First\n2. Second")
    const matches = segment.match(/(\d+\.\s+)/g);
    return matches !== null && matches.length > 1;
  };
  
  const formatList = (segments: string[], key: number): JSX.Element => {

    // Format the collected list segments as a list
    const listItems = segments.map((segment, idx) => {
      const formattedText = segment;
  
      // Split the segment by "-" to create sub-list items
      const subListItems = formattedText.split(/(?=-\s)/).map((subItem, subIdx) => {
        // Emphasize text between pairs of ** in sub-list items
        if (subItem.includes('**')) {
          subItem = subItem.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
        }
  
        return (
          <li
            key={`sub-list-item-${key}-${idx}-${subIdx}`}
            style={{
              listStyleType: 'none',
              marginLeft: '20px',
              textIndent: '-20px',  // Outdent the first line
              paddingLeft: '40px',  // Indent the rest of the lines
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: subItem }} />
          </li>
        );
      });
  
      // If there are multiple sub-items, wrap them in a nested <ul>
      return subListItems.length > 1 ? (
        <li
          key={`list-item-${key}-${idx}`}
          style={{
            listStyleType: 'none',
            marginLeft: '20px',
            textIndent: '-20px',  // Outdent the first line
            paddingLeft: '40px',  // Indent the rest of the lines
          }}
        >
          <ul style={{ paddingLeft: '0' }}>{subListItems}</ul>
        </li>
      ) : (
        subListItems[0]  // If only one item, return it as is
      );
    });
  
    return <ul key={`list-${key}`} style={{ paddingLeft: '0' }}>{listItems}</ul>;
  };
  
  const extractListItems = (segment: string): string[] => {
    // Extracts list items from a segment with sequential numbers (e.g., "1. First\n2. Second")
    return segment.split(/(?=\d+\.\s+)/);
  };
  
  // Usage in formatContent
  const formatContent = (text: string): React.ReactNode => {
    const segments = splitIntoSegments(text);
    let inCodeBlock = false;
    let codeBlockContent = '';
    let listSegments: string[] = [];
    let expectedListNumber = 1;
    let result: React.ReactNode[] = [];
  
    // Helper function to push formatted content into the result array
    const pushFormattedContent = (formattedSegment: string, index: number) => {
      if (formattedSegment.includes('**')) {
        formattedSegment = formattedSegment.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
      }
  
      result.push(<div key={`segment-${index}`} dangerouslySetInnerHTML={{ __html: formattedSegment }} />);
    };
  
    segments.forEach((segment, index) => {
      if (!segment) return;
  
      // Handle code blocks
      if (inCodeBlock) {
        codeBlockContent += '\n' + segment;
        if (segment.endsWith('```')) {
          inCodeBlock = false;
          const formattedCode = formatCodeSnippet(codeBlockContent, index,copiedKey, handleCopy);
          codeBlockContent = '';
          result.push(formattedCode);
        }
        return;
      }
  
      if (isCodeSnippet(segment)) {
        if (segment.startsWith('```') && segment.endsWith('```')) {
          result.push(formatCodeSnippet(segment, index,copiedKey, handleCopy));
          return;
        } else if (segment.startsWith('```')) {
          inCodeBlock = true;
          codeBlockContent = segment;
          return;
        }
      }
  
      // Handle headings (###), converting only the part before the first newline into a heading
      let formattedSegment = segment;
  
        // Handle headings (###), converting only the part before the first newline into a heading
        if (formattedSegment.startsWith('###')) {
            const firstNewlineIndex = formattedSegment.indexOf('\n');
            
            let headingText = formattedSegment;
            let remainingText = '';
        
            if (firstNewlineIndex !== -1) {
            headingText = formattedSegment.substring(0, firstNewlineIndex); // Capture everything before the first newline
            remainingText = formattedSegment.substring(firstNewlineIndex + 1); // Capture everything after the first newline
            }
        
            const headingContent = headingText.replace(/^###\s*/, ''); // Remove "###" and any following space
            
            // Add heading to the result
            result.push(<h3 key={`heading-${index}`}>{headingContent}</h3>);
        
            // If there's remaining text, continue formatting it as well
            if (remainingText) {
            formattedSegment = remainingText; // Continue with the remaining text in the segment
            } else {
            formattedSegment = ''; // Clear the segment if no remaining text
            }
        }
        
      // Handle lists or paragraphs
      const currentListNumber = extractListNumber(formattedSegment);
      if (currentListNumber === expectedListNumber || isListInSegment(formattedSegment)) {
        const extractedListSegments = extractListItems(formattedSegment);
        listSegments = listSegments.concat(extractedListSegments);
  
        // Update the expected list number
        const lastListSegment = extractListNumber(listSegments[listSegments.length - 1]);
        expectedListNumber = lastListSegment !== undefined ? lastListSegment + 1 : 1;
  
        // Check if this is the last list segment or if the next segment continues the list
        const nextSegment = segments[index + 1];
        const nextListNumber = nextSegment ? extractListNumber(nextSegment) : undefined;
  
        // If no more list items or list ends, format the entire list
        if (!nextSegment || nextListNumber !== expectedListNumber) {
          const formattedList = formatList(listSegments, index);
          listSegments = [];
          expectedListNumber = 1;
          result.push(formattedList);
        }
        return; // Skip further processing of this segment as it's part of a list
      }
  
      // Handle images and audio (optional)
      if (isImage(formattedSegment)) {
        result.push(<div key={`image-${index}`} dangerouslySetInnerHTML={{ __html: formattedSegment }} />);
        return;
      } else if (isAudio(formattedSegment)) {
        result.push(<div key={`audio-${index}`} dangerouslySetInnerHTML={{ __html: formattedSegment }} />);
        return;
      }
  
      // If no special formatting applies, treat the rest as a regular paragraph or other format
      pushFormattedContent(formattedSegment, index);
    });
  
    return result.length ? result : undefined;
  };
  

// Function to toggle full-screen mode
  const toggleFullScreen = () => {
    setIsFullScreen(prevState => !prevState);
  };
  

  return (
<div className={`container ${isFullScreen ? 'fullscreen-mode' : ''}`}>
  {!isFullScreen && <NavBar userImage={userImage} onProfileLoad={handleProfileLoad} />}
  <div className={`main-content ${isFullScreen ? 'fullscreen-content' : ''}`}>
       {!isFullScreen && 
        <Sidebar
        onLogout={handleLogout}
        onHomeClick={async () => {
            navigate('/home');
        }}
        />
       }

    <div className={`content-inner ${isFullScreen ? 'fullscreen-chat-container' : ''}`}>
    <div className={`chat-container ${isFullScreen ? 'fullscreen-chat-container' : ''}`} onDoubleClick={toggleFullScreen}>
       {!isFullScreen && 
            <div className="chat-container-icons">
              <ul>
                 <li>
                  <button title='New Chat' onClick={newChat}>
                    <MdChat size={28} />
                  </button>
                </li>

                <li>
                  <button title='Add Assistant' onClick={createAssistant}>
                    <FaUserPlus size={28} />
                  </button>
                </li>

                {assistants.map((assistant, index) => (
                <li key={assistant.id} onClick={handleAssistantClick(index)} onDoubleClick={handleAssistantClick(index)}>
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                    <button
                        style={{
                        position: 'relative',
                        background: 'transparent',
                        border: 'none',
                        color: currentAssistantIndex === index ? '#007bff' : '#8055f5', // Blue if selected
                        }}
                        onMouseEnter={() => setHoveredIndex(index)} // Set hover state on mouse enter
                        onMouseLeave={() => setHoveredIndex(null)}  // Remove hover state on mouse leave
                        title={assistant.name} // Show the assistant's name in a tooltip
                    >
                        <FaUser size={24} style={{ color: currentAssistantIndex === index ? '#007bff' : 'inherit' }} /> {/* Retain original color or blue if selected */}
                        <span
                        style={{
                            position: 'absolute',
                            top: '30%', // Fine-tune the position to merge better
                            left: '90%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '10px', // Make the number smaller to integrate better
                            fontWeight: 'bold',
                            color: hoveredIndex === index || currentAssistantIndex === index ? '#007bff' : '#8055f5', // Blue on hover or if selected, original color otherwise
                            textShadow: '0px 1px 2px rgba(0, 0, 0, 0.5)', // Add shadow for depth
                            pointerEvents: 'none', // Prevent interaction with the virtualId text
                        }}
                        >
                        {assistant.virtualId}
                        </span>
                    </button>
                    </div>
                </li>
                ))}
                  <li>
                  <button title='Remove Assistant' onClick={() => setIsRemoveAssistantOpen(true)}>
                    <FaUserMinus size={28} />
                  </button>
                </li>
              </ul>
            </div>
          }

            <div className="chat-area">
              {currentAssistantIndex !== null ? (
                <>

                <div className="chat-messages">
                {assistants[currentAssistantIndex]?.chat.map((chat, index) => (
                    <div key={index} className={`chat-message ${chat.from}`}>
                    <strong>{chat.from === 'user' ? 'You' : assistants[currentAssistantIndex]?.name}:</strong>
                    {formatContent(chat.message)}
                    </div>
                ))}
                </div>

                {/* Prompt Container */}

                <div className="prompt-container">

                {imagePreviewUrls.length > 0 && (
                <div className="image-preview">
                    {imagePreviewUrls.map((url, index) => (
                    <img key={index} src={url} alt={`Preview ${index + 1}`} style={{ width: '50px', marginBottom: '10px' }} />
                    ))}
                </div>
                )}

                <div className="input-row">
                    <label 
                    title="Upload" 
                    htmlFor="file-upload" 
                    style={{ cursor: 'pointer', alignSelf: 'flex-end' }} 
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                    </svg>
                    </label>
                    <input id="file-upload" type="file" multiple onChange={handleFileUpload} style={{ display: 'none' }} />

                    <textarea
                    value={newMessage}
                    onChange={(e) => {
                        setNewMessage(e.target.value);

                        // Always reset the height if the input is empty
                        if (e.target.value === '') {
                        e.target.style.height = '24px';
                        } else {
                        e.target.style.height = 'auto'; // Reset height to calculate new height based on content
                        e.target.style.height = `${Math.min(e.target.scrollHeight, 300)}px`; // Restrict height to 10 lines (about 300px)
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault(); // Prevent default behavior (adding a new line)
                        handleSendMessage(); // Trigger the send message function
                        setNewMessage(''); // Clear the textarea field after sending
                        
                        // Reset the height of the textarea when user input is empty
                        e.currentTarget.style.height = '24px';
                        }
                    }}
                    placeholder="Type a message"
                    className="message-input"
                    rows={2}
                    />

                <button
                  className="chat-send-button"
                  onClick={() => (loading ? stopStreaming() : handleSendMessage())}
                  aria-label={loading ? 'Stop streaming' : 'Send message'}
                >
                  {loading ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                      <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" fill="none" />
                      <rect x="6" y="6" width="4" height="4" fill="currentColor" />
                    </svg>
                  ) : (
                    <FaPaperPlane size={20} />
                  )}
                </button>

                </div>
                </div>

                </>
              ) : (
                <p>Select an assistant to start chatting.</p>
              )
              
              
              }
            </div>

            {/* Assistant Creation Window */}
            {showAssistantWindow && (
            <div className="modal-overlay">
                <div className="modal-content">
                <h3 className="modal-title">New Assistant Information</h3>

                <div className="inputContainer">
                    <label htmlFor="assistantName" className="label">Assistant Name</label>
                    <input
                    id="assistantName"
                    type="text"
                    name="assistantName"
                    value={assistantName}
                    onChange={(e) => setAssistantName(e.target.value)}
                    placeholder="Enter Assistant Name"
                    required
                    maxLength={50}
                    className="asswindow-input" // Use this class to set width to 60%
                    />
                </div>

                <div className="inputContainer">
                    <label htmlFor="assistantInstructions" className="label">Assistant Instructions</label>
                    <textarea
                    id="assistantInstructions"
                    name="assistantInstructions"
                    value={assistantInstructions}
                    onChange={(e) => setAssistantInstructions(e.target.value)}
                    placeholder="Enter Instructions"
                    required
                    rows={4}
                    className="asswindow-textarea" // Use this class for consistent styling
                    />
                </div>

                <div className="buttonContainer">
                    <button className="cancel-button" onClick={closeAssistantWindow}>
                    Cancel
                    </button>
                    <button className="save-button" onClick={handleCreateAssistant}>Save</button>
                </div>
                </div>
            </div>    
            )}
            {showUpdateAssistantWindow && (
            <div className="modal-overlay">
                <div className="modal-content">
                <h3 className="modal-title">Update Assistant Information</h3>

                <div className="inputContainer">
                    <label htmlFor="assistantName" className="label">Assistant Name</label>
                    <input
                    id="assistantName"
                    type="text"
                    name="assistantName"
                    value={assistantName}
                    onChange={(e) => setAssistantName(e.target.value)}
                    placeholder="Enter Assistant Name"
                    required
                    maxLength={50}
                    className="asswindow-input"
                    />
                </div>

                <div className="inputContainer">
                    <label htmlFor="assistantInstructions" className="label">Assistant Instructions</label>
                    <textarea
                    id="assistantInstructions"
                    name="assistantInstructions"
                    value={assistantInstructions} // Ensure this is set correctly
                    onChange={(e) => setAssistantInstructions(e.target.value)}
                    placeholder="Enter Instructions"
                    required
                    rows={4}
                    className="asswindow-textarea"
                    />
                </div>

                <div className="buttonContainer">
                    <button className="cancel-button" onClick={() => setShowUpdateAssistantWindow(false)}>
                    Cancel
                    </button>
                    <button className="save-button" onClick={handleUpdateAssistant}>
                    Save
                    </button>
                </div>
                </div>
            </div>
            )}

            {/* Assistant Removal Modal */}
            {isRemoveAssistantOpen && (
              <div className="modal-overlay">
                <div className="modal-content" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  <h3 className="modal-title">Remove Assistants</h3>
                  <div className="assistant-selection">
                    <ul className="assistant-remove-list">
                      {assistants.map(assistant => (
                        <li key={assistant.id} className="assistant-item">
                          <input
                            type="checkbox"
                            checked={selectedAssistants.includes(assistant.id)}
                            onChange={() => handleToggleAssistantSelection(assistant.id)}
                            className="assistant-checkbox"
                          />
                          <label className="assistant-checkbox-label">{assistant.name}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="actions">
                    <button className="cancel-button" onClick={() => setIsRemoveAssistantOpen(false)}>Cancel</button>
                    <button className="remove-button" onClick={handleRemoveSelectedAssistants}>Remove Selected</button>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
