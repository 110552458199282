import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, storage } from '../firebaseConfig';
import { signOut } from 'firebase/auth';
import { ref, getDownloadURL, listAll, uploadString,uploadBytes,updateMetadata,getMetadata} from 'firebase/storage';
import { getFirestore, doc, getDoc, setDoc,collection, addDoc, query, onSnapshot,where,getDocs, updateDoc} from 'firebase/firestore';
import NavBar from './NavBar';
import Sidebar from './Sidebar';
import { FaFolderOpen, FaShareAlt, FaFire, FaRandom, FaExpand, FaCompress,FaCheck} from 'react-icons/fa'; 
import './Base.css';

// Define the type for the file object
interface FileType {
  name: string;
  url: string;
  refPath: string;
}

// Define the type for the state passed via location
interface LocationState {
  selectedFile: string | null;
  files: FileType[];
}

// Define the type for the question object
interface QuestionType {
  question: string;
  options?: { [key: string]: string };
  answer: string;
}

const Home: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { selectedFile: navigatedSelectedFile, files: navigatedFiles, selectedSubject: navigatedSubject} = 
    (location.state as LocationState & { selectedSubject?: string; subjects?: string[] }) || { 
      selectedFile: null, 
      files: [], 
      selectedSubject: null, 
      subjects: [] 
    };

  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | null>(navigatedSelectedFile || null);
  const [subjectFromNav] = useState<string | null>(navigatedSubject || null);
  const [files, setFiles] = useState<FileType[]>(navigatedFiles || []); 
  const [isLibraryModalOpen, setIsLibraryModalOpen] = useState(false);
  const [subjects, setSubjects] = useState<string[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
  const [savedLibraryUrl, setSavedLibraryUrl] = useState<string>(''); 
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [resultMessage, setResultMessage] = useState<string | null>(null);
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false);
  const hasFetchedFiles = useRef(false); 
  const [isAnonymousUser, setIsAnonymousUser] = useState<boolean>(false); // New state to track anonymous user
  const [isShareModalOpen, setIsShareModalOpen] = useState(false); // New state for the share modal
  const [shareEmail, setShareEmail] = useState(''); // State for recipient's email
  const [shareMessage, setShareMessage] = useState(''); // State for sharing message
  const [shuffledQuestions, setShuffledQuestions] = useState<QuestionType[]>([]); // New state for shuffled questions
  const [isShuffled, setIsShuffled] = useState(false); // To track if questions are shuffled or in original order
  const questionContainerRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isTouching, setIsTouching] = useState(false); // New flag to track touch events
  const userUIDRef = useRef<string | null>(null);
  const [hasFetched, setHasFetched] = useState(false); // New state to track if fetch has occurred

  console.log('subjetNavigated', subjectFromNav);

  const [questionContainerColor, setQuestionContainerColor] = useState<string>('#2b2f33');
  const [optionBackgroundColor, setOptionBackgroundColor] = useState<string>('#282c34'); // Default color for options
  const [streaks, setStreaks] = useState<number>(0); 


  // Disable scrolling when the component is mounted
  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      if (!currentUser.isAnonymous && !currentUser.emailVerified) {
        // If the user is not anonymous and their email is not verified
        alert('Please verify your email before accessing this page.');
        navigate('/auth'); // Redirect to login/auth page
      }
    }
  }, [navigate]);
  
  useEffect(() => {
    const checkSubscriptionAndCopyFiles = async () => {
      const currentUser = auth.currentUser;

      if (currentUser) {
        const firestore = getFirestore();
        userUIDRef.current = currentUser.uid;

        try {
          const userDocRef = doc(firestore, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          // Check if the document exists
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const hasFetchedInFirestore = userData?.hasFetched || false;

            setHasFetched(hasFetchedInFirestore);

            // If hasFetched is true, skip copying and fetching
            if (hasFetchedInFirestore) {
              console.log("Skipping fetching and copying, as it's already done.");
              return;
            }

            // Proceed to copy files and fetch questions if not fetched yet
            if (currentUser.isAnonymous) {
              setIsAnonymousUser(currentUser.isAnonymous);
              await copyFilesForAnonymousOrUnsubscribedUser(currentUser.uid);
              await fetchQuestionsForCopiedFiles(currentUser.uid); // Fetch questions after copying files
            } else {
              const subscriptionStatusInFirestore = userData?.subscriptionStatus || false;
              const hasCopiedFiles = userData?.hasCopiedFiles || false;

              if (!subscriptionStatusInFirestore && !hasCopiedFiles) {
                await copyFilesForAnonymousOrUnsubscribedUser(currentUser.uid);
                await updateDoc(userDocRef, { hasCopiedFiles: true });
                await fetchQuestionsForCopiedFiles(currentUser.uid);
              }
            }

            // Mark as fetched and update Firestore
            setHasFetched(true);
            await updateDoc(userDocRef, { hasFetched: true });
          } else {
            // If the document doesn't exist, create it and mark as fetched
            console.log('User document does not exist, creating...');
            // Proceed to copy files and fetch questions if not fetched yet
            if (currentUser.isAnonymous) {
              setIsAnonymousUser(currentUser.isAnonymous);
              await copyFilesForAnonymousOrUnsubscribedUser(currentUser.uid);
              await fetchQuestionsForCopiedFiles(currentUser.uid); // Fetch questions after copying files
              await setDoc(userDocRef, { hasFetched: true });
              setHasFetched(true);
            } else {
              const userData = userDoc.data();
              const subscriptionStatusInFirestore = userData?.subscriptionStatus || false;
              const hasCopiedFiles = userData?.hasCopiedFiles || false;
              if (!subscriptionStatusInFirestore && !hasCopiedFiles) {
                await copyFilesForAnonymousOrUnsubscribedUser(currentUser.uid);
                await updateDoc(userDocRef, { hasCopiedFiles: true });
                await fetchQuestionsForCopiedFiles(currentUser.uid);
              }
            }
          }

        } catch (error) {
          console.error("Error fetching user data or performing copy/fetch operations:", error);
        }
      }
    };

    const copyFilesForAnonymousOrUnsubscribedUser = async (uid: string) => {
      try {
        const originalFileName = 'History of the United States - Wikipedia.pdf';
        const newFileName = `${uid}_History of the United States - Wikipedia.pdf`;
        const originalFileRef = ref(storage, `admin/${originalFileName}`);
        const newFileRef = ref(storage, `uploads/${newFileName}`);

        const originalFileUrl = await getDownloadURL(originalFileRef);
        const originalFileResponse = await fetch(originalFileUrl);
        const originalFileBlob = await originalFileResponse.blob();

        await uploadBytes(newFileRef, originalFileBlob);
        console.log(`File copied to uploads/ as ${newFileName}`);

        const originalQnaFileName = 'History of the United States - Wikipedia.pdf_qna.json';
        const newQnaFileName = `${uid}_History of the United States - Wikipedia.pdf_qna.json`;
        const originalQnaFileRef = ref(storage, `admin/${originalQnaFileName}`);
        const newQnaFileRef = ref(storage, `Q&A/${newQnaFileName}`);

        const originalQnaFileUrl = await getDownloadURL(originalQnaFileRef);
        const originalQnaFileResponse = await fetch(originalQnaFileUrl);
        const originalQnaFileBlob = await originalQnaFileResponse.blob();

        await uploadBytes(newQnaFileRef, originalQnaFileBlob);
        console.log(`Q&A file copied to Q&A/ as ${newQnaFileName}`);
      } catch (error) {
        console.error('Error copying files for anonymous/unsubscribed user:', error);
      }
    };

    const fetchQuestionsForCopiedFiles = async (uid: string) => {
      try {
        const qnaFileRef = ref(storage, `Q&A/${uid}_History of the United States - Wikipedia.pdf_qna.json`);
        const url = await getDownloadURL(qnaFileRef);
        const response = await fetch(url);
        const data = await response.json();
        setQuestions(data || []);
      } catch (error) {
        console.error("Error fetching questions after copying files:", error);
        setQuestions([]);
      }
    };

    // Execute only if `hasFetched` is not true
    if (hasFetched === false) {
      checkSubscriptionAndCopyFiles();
    }
  }, [hasFetched]);

  useEffect(() => {
    const questionContainer = questionContainerRef.current;
    if (!questionContainer) return;
  
    let startY = 0;
    let endY = 0;
    let touchStartTime = 0;
    const minSwipeDistance = 20; // Minimum distance for swipe to be detected
    const maxTapDuration = 80; // Max duration (ms) to be considered a tap
    let isButtonInteraction = false;
  
    const handleTouchStart = (e: TouchEvent) => {
      setIsTouching(true);
      startY = e.touches[0].clientY;
      touchStartTime = Date.now();
      // Check if the target is a button or any other interactive element
      isButtonInteraction =
        (e.target instanceof HTMLButtonElement) ||
        (e.target instanceof HTMLInputElement) ||
        (e.target instanceof HTMLTextAreaElement) ||
        (e.target instanceof HTMLDivElement && e.target.className.includes('show-answer-button')); // Assuming class name for answer button
    };
  
    const handleTouchMove = (e: TouchEvent) => {
      endY = e.touches[0].clientY;
    };
  
    const handleTouchEnd = () => {
      const touchDuration = Date.now() - touchStartTime;
      const distance = startY - endY;
  
      // Handle only swipes, ignore taps
      if (!isButtonInteraction && touchDuration > maxTapDuration && Math.abs(distance) > minSwipeDistance) {
        if (distance > 0) {
          // Swipe up
          setShowAnswer(false);
          setCurrentIndex((prevIndex) => (prevIndex + 1) % questions.length);
        } else {
          // Swipe down
          setShowAnswer(false);
          setCurrentIndex((prevIndex) => (prevIndex - 1 + questions.length) % questions.length);
        }
      }
  
      setIsTouching(false);
    };
  
    questionContainer.addEventListener('touchstart', handleTouchStart);
    questionContainer.addEventListener('touchmove', handleTouchMove);
    questionContainer.addEventListener('touchend', handleTouchEnd);
  
    return () => {
      questionContainer.removeEventListener('touchstart', handleTouchStart);
      questionContainer.removeEventListener('touchmove', handleTouchMove);
      questionContainer.removeEventListener('touchend', handleTouchEnd);
    };
  }, [questions.length]);
  

  // Fetch user profile settings from Firestore on mount and listen for real-time updates
  useEffect(() => {
    const fetchUserSettings = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const firestore = getFirestore();
        const userDocRef = doc(firestore, 'users', currentUser.uid);

        // Real-time listener for changes in the user's settings
        const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            const settings = userData?.settings;
            if (settings) {
              setQuestionContainerColor(settings.questionContainerColor ||'#2b2f33');
              setOptionBackgroundColor(settings.optionBackgroundColor || '#282c34');
            }
          }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
      }
    };

    fetchUserSettings();
  }, []);
  

  // Check if the user is anonymous
  useEffect(() => {
    const checkUserAuth = () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        setIsAnonymousUser(currentUser.isAnonymous);
      }
    };
    checkUserAuth();
  }, []);

  useEffect(() => {
    const initializeSelectedFile = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.warn("No user is authenticated.");
        return;
      }

      if (!selectedFile) {
        const firestore = getFirestore();
        try {
          const userDocRef = doc(firestore, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            console.log('userData', userData);

            if (userData && userData.selectedFileUrl) {
              console.log('userData selectedFileURL', userData.selectedFileUrl);
              setSelectedFile(userData.selectedFileUrl);
            }
          }
        } catch (error) {
          console.error("Error fetching selected file from Firestore:", error);
        }
      }
    };

    initializeSelectedFile();
  }, [selectedFile]);


  useEffect(() => {
      const fetchQuestions = async () => {
        const currentUser = auth.currentUser;
        if (!currentUser) return;
    
        if (!currentUser.isAnonymous) {
          if (subjectFromNav && savedLibraryUrl) {
            try {
              const fileName = subjectFromNav;
              const qnaFileRef = ref(storage, `library/${currentUser.uid}_${fileName}_qna.json`);
              console.log('qnafileref', qnaFileRef);
    
              const url = await getDownloadURL(qnaFileRef);
              console.log('codes get here for url')
              console.log('url', url);
              const response = await fetch(url);
              const data = await response.json();
              console.log('data', data);
              setQuestions(data || []);
            } catch (error) {
              console.error("Error fetching questions:", error);
              setQuestions([]);
            }
          }
        } else {
          console.log('code got here')
          try {
            const listRef = ref(storage, 'Q&A/');
            const res = await listAll(listRef);
    
            // Find the first file that includes the user's UID in the file name
            const file = res.items.find((item) => item.name.includes(currentUser.uid));
    
            if (file) {
              const fileName = file.name.replace(`${currentUser.uid}_`, '').replace('_qna.json', '');
    
              const qnaFileRef = ref(storage, `Q&A/${currentUser.uid}_${fileName}_qna.json`);
              console.log('qnafileref', qnaFileRef);
    
              const url = await getDownloadURL(qnaFileRef);
              console.log('url', url);
              const response = await fetch(url);
              const data = await response.json();
              console.log('data', data);
              setQuestions(data || []);
            }
          } catch (error) {
            console.error("Error fetching questions:", error);
            setQuestions([]);
          }
        }
      };
    
      fetchQuestions();
    }, [subjectFromNav, savedLibraryUrl]);
  
    const fetchFiles = useCallback(async () => {
      if (hasFetchedFiles.current) return;
    
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.warn("No user is authenticated, skipping file fetching.");
        return;
      }
    
      try {
        const userUID = currentUser.uid;
    
        // Helper function to fetch files from a specific folder
        const fetchFilesFromFolder = async (folderPath: string) => {
          const listRef = ref(storage, folderPath);
          const res = await listAll(listRef);
    
          const userFiles = res.items
            .filter((item) => item.name.includes(userUID))
            .map(async (item) => ({
              name: item.name.replace(`${userUID}_`, ''), 
              url: await getDownloadURL(item),
              refPath: item.fullPath, 
            }));
    
          return Promise.all(userFiles);
        };
    
        // Fetch files from both 'uploads' and 'summaries' folders
        const [uploadFiles, summaryFiles] = await Promise.all([
          fetchFilesFromFolder('uploads/'),
          fetchFilesFromFolder('summaries/')
        ]);
    
        // Combine the files from both folders
        const allFiles = [...uploadFiles, ...summaryFiles];
        setFiles(allFiles);
    
        hasFetchedFiles.current = true;
      } catch (error) {
        console.error("Error fetching files from Firebase Storage:", error);
      }
    }, []);
    

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const handleViewNotesPage = () => {
    navigate('/notes');
  };


  // Handle sharing the question
  const handleSendShare = async () => {
    const firestore = getFirestore();
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    try {
      const receiverUser = await getUserByEmail(shareEmail);
      if (!receiverUser) {
        alert('User with this email does not exist.');
        return;
      }

      const receiverUID = receiverUser.uid;
      const currentQuestion = questions[currentIndex];

      await addDoc(collection(firestore, 'notifications'), {
        receiverId: receiverUID,
        senderId: currentUser.uid,
        fileType: 'question',
        message: `${currentUser.email} has shared a question with you: ${currentQuestion.question}`,
        question: currentQuestion.question,
        answer: currentQuestion.answer,
        options: currentQuestion.options || null,
        status: 'pending',
        createdAt: new Date(),
        isRead: false,
      });

      alert('Question shared successfully!');
      setIsShareModalOpen(false); // Close the modal
    } catch (error) {
      console.error('Error sharing question:', error);
      alert('Failed to share the question. Please try again.');
    }
  };


    // Get user by email
    const getUserByEmail = async (email: string) => {
      const firestore = getFirestore();
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('email', '==', email));
  
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        return { uid: userDoc.id, ...userDoc.data() };
      }
      return null;
    };


  const handleHomeClick = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
  
    const firestore = getFirestore();
  
    if (!currentUser.isAnonymous) {
      // Non-anonymous user logic
      try {
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const fetchedSelectedFileUrl = userData?.selectedFileUrl || null;
          setSelectedFile(fetchedSelectedFileUrl);
  
          if (fetchedSelectedFileUrl) {
            console.log("Fetched selectedFileUrl:", fetchedSelectedFileUrl);
          } else {
            console.warn("No selectedFileUrl found in Firestore.");
          }
        } else {
          console.warn("No user document found in Firestore.");
        }
      } catch (error) {
        console.error("Error fetching selectedFileUrl from Firestore:", error);
      }
    } else {
      // Anonymous user logic
      try {
        const listRef = ref(storage, 'Q&A/');
        const res = await listAll(listRef);
  
        // Find the first file that includes the user's UID in the file name
        const file = res.items.find((item) => item.name.includes(currentUser.uid));
  
        if (file) {
          const fileName = file.name.replace(`${currentUser.uid}_`, '').replace('_qna.json', '');
  
          try {
            const qnaFileRef = ref(storage, `Q&A/${currentUser.uid}_${fileName}_qna.json`);
            console.log('qnafileref', qnaFileRef);
  
            const url = await getDownloadURL(qnaFileRef);
            console.log('url', url);
            const response = await fetch(url);
            const data = await response.json();
            console.log('data', data);
            setQuestions(data || []);
          } catch (error) {
            console.error("Error fetching questions:", error);
            setQuestions([]);
          }
        } else {
          console.warn("No file found for anonymous user in Q&A folder.");
          setQuestions([]); // Set questions to an empty array if no file is found
        }
      } catch (error) {
        console.error("Error fetching file for anonymous user:", error);
      }
    }
  
    navigate('/home');
  };
  
  useEffect(() => {
    const fetchQuestions = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      let fileName = files.find(file => file.url === selectedFile)?.name;
      console.log('fileName', fileName);

      let qnaFileRef;
      
      console.log('selectedFile1',selectedFile)

      if (!selectedFile || !fileName) {
        try {
          const firestore = getFirestore();
          const userDocRef = doc(firestore, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            const fetchedSavedLibraryUrl = userData?.savedLibraryUrl || '';
            setSavedLibraryUrl(fetchedSavedLibraryUrl);
            
            if (fetchedSavedLibraryUrl) {
              const subjectName = fetchedSavedLibraryUrl.split('_').slice(1, -1).join('_');
              fileName = subjectName;
              console.log('fileName through home click', fileName);
              qnaFileRef = ref(storage, `library/${currentUser.uid}_${fileName}_qna.json`);
              console.log('qnafileref through home click', qnaFileRef);
              try {
                const url = await getDownloadURL(qnaFileRef);
                console.log('url', url);
                const response = await fetch(url);
                const data = await response.json();
                console.log('data', data);
                setQuestions(data || []);
              } catch (error) {
                console.error("Error fetching questions:", error);
                setQuestions([]);
              }
              return;
            }
          }
        } catch (error) {
          console.error("Error fetching savedLibraryUrl from Firestore:", error);
        }
      }

      if (selectedFile && fileName) {
        qnaFileRef = ref(storage, `Q&A/${currentUser.uid}_${fileName}_qna.json`);
      }
   
      console.log('question file name',`Q&A/${currentUser.uid}_${fileName}_qna.json`)

      if (!qnaFileRef) return;

      try {
        const url = await getDownloadURL(qnaFileRef);
        console.log('url', url);
        const response = await fetch(url);
        const data = await response.json();
        console.log('data', data);
        setQuestions(data || []);
      } catch (error) {
        console.error("Error fetching questions:", error);
        setQuestions([]);
      }
    };

    fetchQuestions();

  }, [selectedFile, files]);


  const handleAddToLibraryClick = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    try {
      const listRef = ref(storage, `library/`);
      const res = await listAll(listRef);

      const userSubjects = res.items
        .filter((item) => item.name.includes(currentUser.uid))
        .map((item) => item.name.split('_').slice(1, -1).join('_'));

      setSubjects(userSubjects);
    } catch (error) {
      console.error("Error fetching subjects from Firebase Storage:", error);
    }

    setIsLibraryModalOpen(true);
  };

  const addToLibrary = async () => {
    if (!selectedSubject) return;
    const currentUser = auth.currentUser;
    if (!currentUser) return;
  
    const subjectFileName = `library/${currentUser.uid}_${selectedSubject}_qna.json`;
    const subjectRef = ref(storage, subjectFileName);
  
    try {
      // Fetch the file's existing metadata before updating
      const metadata = await getMetadata(subjectRef);
      
      const url = await getDownloadURL(subjectRef);
      const response = await fetch(url);
      const existingData = await response.json();
  
      const newQuestion: QuestionType = {
        question: questions[currentIndex].question,
        answer: questions[currentIndex].answer,
      };
  
      if (questions[currentIndex].options) {
        newQuestion.options = questions[currentIndex].options;
      }
  
      const updatedData = [...existingData, newQuestion];
  
      // Update the file with new data
      await uploadString(subjectRef, JSON.stringify(updatedData), 'raw', {
        contentType: 'application/json',
      });
  
      // After the file upload, update its metadata with the same metadata as before
      const customMetadata = {
        customMetadata: {
          ...metadata.customMetadata, // Retain existing metadata
          UserID: currentUser.uid,
          ShareWithPublic: metadata.customMetadata?.ShareWithPublic || 'No',
          DocumentType: 'Subject',
        },
      };
  
      // Update the file's metadata with the preserved and new custom metadata
      await updateMetadata(subjectRef, customMetadata);
  
      alert("Question added to the library successfully!");
    } catch (error) {
      console.error("Error adding question to library:", error);
      alert("Failed to add question to the library.");
    }
  
    setIsLibraryModalOpen(false);
  };
  
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadBio: string) => {
    setUserImage(loadedImageUrl);
  };


  const handleOptionClick = (optionKey: string) => {
    if (isTouching) return; // Prevent option click during touch interaction
    setSelectedOption(optionKey);
    setShowSubmitButton(true);
    setResultMessage(null);
  };

  const handleSubmit = () => {
    if (selectedOption) {
      const isCorrect = selectedOption === questions[currentIndex].answer;
      setResultMessage(isCorrect ? 'Correct!' : 'Incorrect');

      // Update streaks if correct, or reset if incorrect
      if (isCorrect) {
        setStreaks((prev) => prev + 1);
      } else {
        setStreaks(0);
      }

      setShowSubmitButton(false);
    }
  };

  const handleShowAnswerClick = () => {
    if (isTouching) return; // Prevent hide/show answer during touch interaction
    setShowAnswer(!showAnswer);
  };

  useEffect(() => {
    setResultMessage(null);
    setSelectedOption(null);
    setShowSubmitButton(false);
  }, [currentIndex]);

  if (selectedFile === '' && savedLibraryUrl === '') {
    return (
      <div className="container-home">
        <NavBar userImage={userImage} onProfileLoad={handleProfileLoad} />
        <div className="main-content">
          <Sidebar onLogout={handleLogout} onHomeClick={handleHomeClick} />
          <div className="content-inner">
            <p  style={{ color: 'white' }}>No file selected. Please go back and select a file.</p>
            <p 
              onClick={handleViewNotesPage} 
              style={{
                marginTop: '20px',
                color: 'white',
                cursor: 'pointer',
                textDecoration: 'underline',
                fontSize: '14px'
              }}
            >
              Go Back to Notes
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (questions.length === 0) {
    return (
      <div className="container-home">
        <NavBar userImage={userImage} onProfileLoad={handleProfileLoad} />
        <div className="main-content">
          <Sidebar onLogout={handleLogout} onHomeClick={handleHomeClick} />
          <div className="content-inner">
            <p  style={{ color: 'white' }}>No questions available for the selected file.</p>
            <p 
              onClick={handleViewNotesPage} 
              style={{
                marginTop: '20px',
                color: 'white',
                cursor: 'pointer',
                textDecoration: 'underline',
                fontSize: '14px'
              }}
            >
              Go Back to Notes
            </p>
          </div>
        </div>
      </div>
    );
  }

  // Function to shuffle the questions in a temporary manner
  const handleShuffleClick = () => {
    if (!isShuffled) {
      const shuffled = [...questions].sort(() => 0.5 - Math.random());
      setShuffledQuestions(shuffled);
      setIsShuffled(true);
    } else {
      setIsShuffled(false); // Reset to original order when clicked again
    }
  };

   // Set the displayed questions based on whether shuffle mode is active
   const displayedQuestions = isShuffled ? shuffledQuestions : questions;

  return (
    <div className={`container-home ${isFullscreen ? 'fullscreen-mode' : ''}`}>
      {!isFullscreen && (
        <NavBar 
          userImage={userImage} 
          onProfileLoad={handleProfileLoad} 
        />
      )}
      <div className={`main-content ${isFullscreen ? 'fullscreen-content' : ''}`}>
      {!isFullscreen && (
      <Sidebar onLogout={handleLogout} onHomeClick={handleHomeClick} />
       )}
      
      <div className={`content-inner ${isFullscreen ? 'fullscreen-question-container' : ''}`} ref={questionContainerRef} >
        {displayedQuestions.length > 0 && (
          <div
          className={`question-container`}
          style={{
            backgroundColor: questionContainerColor,
            height: isFullscreen ? 'calc(100vh - 150px)' : 'calc(100vh - 130px)', // Full height in fullscreen mode
          }}
            onMouseDown={(e) => {
              e.preventDefault();
              const startY = e.clientY;

              document.onmousemove = (moveEvent) => {
                moveEvent.preventDefault();
                const moveY = moveEvent.clientY;

                if (startY - moveY > 50) {
                  setShowAnswer(false);
                  setCurrentIndex((prevIndex) => (prevIndex + 1) % displayedQuestions.length);
                  document.onmousemove = null;
                } else if (moveY - startY > 50) {
                  setShowAnswer(false);
                  setCurrentIndex((prevIndex) => (prevIndex - 1 + displayedQuestions.length) % displayedQuestions.length);
                  document.onmousemove = null;
                }
              };

              document.onmouseup = () => {
                document.onmousemove = null;
              };
            }}
          >
              <p>{displayedQuestions[currentIndex].question}</p>

              {displayedQuestions[currentIndex].options && (
                <ul>
                  {Object.entries(displayedQuestions[currentIndex].options!).map(([key, value]) => (
                    <li
                      key={key}
                      className={selectedOption === key ? 'selected' : ''}
                      onClick={() => handleOptionClick(key)}
                      style={selectedOption !== key ? { backgroundColor: optionBackgroundColor } : {}} 
                    >
                      <strong>{key}:</strong> {value}
                    </li>
                  ))}
                </ul>
              )}


            {resultMessage && (
              <p className={`${resultMessage === "Correct!" ? "correct" : "incorrect"}`}>
                {resultMessage}
              </p>
            )}

            {showAnswer && (
              <p className="correct-answer">
                <strong>Correct Answer:</strong>
                <span style={{ marginLeft: '10px' }}>{displayedQuestions[currentIndex].answer}</span>
             </p>

              )}

            <button className="show-answer-button" onClick={handleShowAnswerClick}>
              {showAnswer ? 'Hide Answer' : 'Show Answer'}
            </button>

            <div className="question-container-icons">
              {/* Conditionally show "Add to Library" and "Share" buttons only for non-anonymous users */}
              {!isAnonymousUser && (
                <>
                  <button title="Add to Library" onClick={handleAddToLibraryClick} className="library-icon">
                    <FaFolderOpen size={28} />
                  </button>
                  <button title="Share" onClick={() => setIsShareModalOpen(true)} className="share-icon">
                    <FaShareAlt size={28} />
                  </button>
                </>
              )}

              {/* Show the following buttons for both anonymous and non-anonymous users */}
              <button title="Streaks" className="streak-icon">
                <FaFire size={28} />
                {streaks > 0 && (
                  <span className="streak-badge" style={streakBadgeStyle(streaks)}>
                    {streaks}
                  </span>
                )}
              </button>

              <button
                title="Shuffle Questions"
                className={`shuffle-icon ${isShuffled ? 'shuffled' : ''}`}
                onClick={handleShuffleClick}
              >
                <FaRandom size={28} />
              </button>

              <button
                title={isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
                className="fullscreen-icon"
                onClick={() => setIsFullscreen(!isFullscreen)}
              >
                {isFullscreen ? <FaCompress size={28} /> : <FaExpand size={28} />}
              </button>

              {showSubmitButton && !resultMessage && (
                <button className="submit-icon" title="Submit" onClick={handleSubmit}>
                  <FaCheck size={28} />
                </button>
              )}
            </div>
            </div>
          )}

      {isShareModalOpen && (
            <div className="modal-overlay">
              <div className="modal-content notes-share-modal">
                <h3 className="modal-title">Share Question</h3>
                <div className="modal-row notes-share-row">
                  <label className="notes-share-label">To:</label>
                  <input
                    type="email"
                    value={shareEmail}
                    onChange={(e) => setShareEmail(e.target.value)}
                    placeholder="Enter recipient's email"
                    className="notes-share-input"
                  />
                </div>
                <div className="modal-row notes-share-row">
                  <label className="notes-share-label">Message:</label>
                  <textarea
                    value={shareMessage}
                    onChange={(e) => setShareMessage(e.target.value)}
                    placeholder="Enter your message"
                    className="notes-share-textarea"
                  />
                </div>
                <div className="modal-actions notes-share-actions">
                  <button className="cancel-button" onClick={() => setIsShareModalOpen(false)}>
                    Cancel
                  </button>
                  <button className="notes-share-send-button" onClick={handleSendShare}>
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}

        {isLibraryModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content stylish-modal">
              <h3 className="modal-title">Select Subject to Add Question</h3>
              
              <select
                value={selectedSubject || ''}
                onChange={(e) => setSelectedSubject(e.target.value)}
                className="stylish-select"
              >
                <option value="" disabled>Select a subject</option>
                {subjects.sort().map((subject, index) => (
                  <option key={index} value={subject}>{subject}</option>
                ))}
              </select>

              <div className="modal-actions">
                <button className="cancel-button stylish-button" onClick={() => setIsLibraryModalOpen(false)}>
                  Cancel
                </button>
                <button className="add-button stylish-button" onClick={addToLibrary}>
                  Add to Library
                </button>
              </div>
            </div>
          </div>
        )}

      </div>

      </div>
    </div>
  );
};

// Function to style the streak badge based on streak count
const streakBadgeStyle = (streaks: number) => ({
  display: 'inline-block',
  backgroundColor: streaks > 0 ? 'red' : 'transparent',
  color: 'white',
  padding: '2px 8px',
  borderRadius: '50%',
  marginLeft: '5px',
});

export default Home;
