import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, storage } from '../firebaseConfig';
import { listAll, getDownloadURL, ref, getMetadata,uploadBytes, updateMetadata  } from 'firebase/storage'; 
import { signOut } from 'firebase/auth';
import NavBar from './NavBar';
import Sidebar from './Sidebar';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import './Base.css';
import { FirebaseError } from 'firebase/app';
import { FaInfoCircle, FaEye, FaSearch, FaDownload, FaFolderOpen} from 'react-icons/fa'; 
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const Explore: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [files, setFiles] = useState<{ name: string; url: string; refPath: string; documentType: string }[]>([]);
  const [filteredFiles, setFilteredFiles] = useState(files); 
  const [searchQuery, setSearchQuery] = useState(''); 
  const [selectedDocType, setSelectedDocType] = useState<string>('All'); // New state for document type selection
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const filesFetchAttempted = useRef(false);
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton } = zoomPluginInstance;
  const toolbarPluginInstance = toolbarPlugin();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false); 
  const [fileInfo, setFileInfo] = useState<{ fileName: string, createdDate: string, uploadedBy: string } | null>(null); // File info state
  const user = auth.currentUser;

  // Disable scrolling when the component is mounted
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  // Handler for toggling the menu
  const toggleMenu = (index: number) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdownElement = document.querySelector('.menu-content');
      const isClickInside = dropdownElement?.contains(event.target as Node);
      if (!isClickInside) {
        setOpenMenuIndex(null);
      } 
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchFiles = async () => {
      if (filesFetchAttempted.current) return; // Exit if files have already been fetched
  
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.warn("No user is authenticated, skipping file fetching.");
        return;
      }

      const folderPaths = ['summaries/', 'Q&A/', 'library/', 'uploads/'];
  
      try {
        const allFiles = await Promise.all(
          folderPaths.map(async (folderPath) => {
            const listRef = ref(storage, folderPath);
            const res = await listAll(listRef);

            const folderFiles = await Promise.all(
              res.items.map(async (item) => {
                try {
                  const metadata = await getMetadata(item); 
                  const shareWithPublic = metadata.customMetadata?.ShareWithPublic;
                  const documentType = metadata.customMetadata?.DocumentType || ''; // Fetch DocumentType from metadata

                  if (shareWithPublic === 'Yes') {
                    const fileUrl = await getDownloadURL(item);
                    return {
                      name: item.name.substring(item.name.indexOf('_') + 1),
                      url: fileUrl,
                      refPath: item.fullPath,
                      documentType: documentType, // Add documentType to the file object
                    };
                  }
                } catch (metadataError) {
                  console.warn(`Failed to get metadata for file ${item.name}`, metadataError);
                  return null;
                }
                return null;
              })
            );

            return folderFiles.filter((file): file is { name: string; url: string; refPath: string; documentType: string } => file !== null);
          })
        );
  
        const flattenedFiles = allFiles.flat();
        setFiles(flattenedFiles);
        setFilteredFiles(flattenedFiles); // Initialize filteredFiles
        filesFetchAttempted.current = true; // Mark files as fetched
      } catch (error) {
        if (error instanceof FirebaseError && error.code !== 'permission-denied') {
          console.error("Error fetching files from storage:", error);
        }
      }
    };
  
    if (auth.currentUser) {
      fetchFiles();
    } else {
      console.warn('No user is authenticated, skipping Firestore operations.');
    }
  }, []);
  
  // Handle search input changes
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterFiles(query, selectedDocType); // Apply filtering based on both search query and document type
  };

  // Handle document type selection from dropdown
  const handleSelectDocType = (docType: string) => {
    setSelectedDocType(docType);
    filterFiles(searchQuery, docType); // Apply filtering based on both search query and selected docType
  };

  // Filter files based on search query and document type
  const filterFiles = (query: string, docType: string) => {
    const filtered = files.filter((file) => {
      const matchesSearch = file.name.toLowerCase().includes(query);
      const matchesDocType = docType === 'All' || file.documentType === docType;
      return matchesSearch && matchesDocType;
    });
    setFilteredFiles(filtered);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string, loadBio: string) => {
    setUserImage(loadedImageUrl);
  };

    // Function to detect file type by extension
    const getFileExtension = (filename: string): string | null => {
      const ext = filename.split('.').pop()?.toLowerCase();
      return ext ? ext : null; // Return null if extension is undefined
    };
    

  // Function to view the file and determine if it's a PDF or JSON
  const handleViewFile = (url: string, name: string) => {
    const extension = getFileExtension(name);
  
    if (url) {
      setFileUrl(url); // Ensure that url is not undefined before setting
    } else {
      setFileUrl(null); // Fallback to null if url is undefined
    }
  
    if (extension) {
      setFileType(extension); // Ensure extension is defined
    } else {
      setFileType(null); // Fallback to null if extension is undefined
    }
  
    setIsFileModalOpen(true); // Open the modal
  };
  
  const handleHomeClick = async () => {
    navigate('/home');
  };

  const handleDownloadFile = async (fileUrl: string) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
  
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
  
      // Extract the filename from the URL and clean it up
      let fileName = fileUrl.split('/').pop() || 'downloaded-file';
      
      // Decode the filename from URL encoding (e.g., %20 becomes a space)
      fileName = decodeURIComponent(fileName);
      
      // Remove the UID prefix if it exists (everything before the first underscore)
      const underscoreIndex = fileName.indexOf('_');
      if (underscoreIndex !== -1) {
        fileName = fileName.substring(underscoreIndex + 1); // Keep everything after the first underscore
      }
  
      // Remove any query parameters (everything after the file extension)
      const extensionIndex = fileName.indexOf('.');
      if (extensionIndex !== -1) {
        // This regex will match the extension and everything after it (query params)
        const queryStart = fileName.indexOf('?');
        if (queryStart !== -1) {
          fileName = fileName.substring(0, queryStart); // Remove everything after the first `?`
        }
      }
  
      downloadLink.download = fileName;
  
      // Append the link to the body, trigger a click event, and then remove the link
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
  
      // Clean up the object URL
      URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  
  // Function to open the file information modal
  const handleShowFileInfo = async (fileRefPath: string) => {
    try {
      const fileRef = ref(storage, fileRefPath);
      const metadata = await getMetadata(fileRef);

      const fileName = fileRefPath.split('/').pop() || 'Unknown';
      const createdDate = new Date(metadata.timeCreated).toLocaleDateString();
      const userID = metadata.customMetadata?.UserID || 'Unknown';

      // Fetch user's name from Firestore based on the UserID
      const firestore = getFirestore();
      const userDocRef = doc(firestore, 'users', userID);
      const userDoc = await getDoc(userDocRef);
      const uploadedBy = userDoc.exists() ? `${userDoc.data()?.firstName || ''} ${userDoc.data()?.lastName || ''}` : 'Unknown';

      setFileInfo({
        fileName: fileName.substring(fileName.indexOf('_') + 1),
        createdDate: createdDate,
        uploadedBy: uploadedBy
      });

      setIsInfoModalOpen(true); // Open the info modal
    } catch (error) {
      console.error('Error fetching file information:', error);
    }
  };

  const handleCollectFile = async (fileRefPath: string, fileName: string, documentType: string) => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const currentUserUID = currentUser.uid;

    // Fetch the user's profile from Firestore to check the subscription status
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', currentUserUID);
    const userDoc = await getDoc(userDocRef);
    const userData = userDoc.data();
    const isSubscriber = userData?.subscriptionStatus === true; // Check subscription status

    // Determine the folder based on the document type
    const folderMap: { [key: string]: string } = {
        'Note': 'uploads',
        'Question': 'Q&A',
        'Subject': 'library',
        'Summary': 'summaries',
    };

    const folder = folderMap[documentType];
    if (!folder) {
        console.error('Invalid document type');
        return;
    }

    try {
        const listRef = ref(storage, `${folder}/`);
        const res = await listAll(listRef);

        // Check if the user already has more than 2 files in the folder (non-subscriber restriction)
        const existingFiles = res.items.filter((item) => item.name.startsWith(`${currentUserUID}_`));
        if (!isSubscriber && existingFiles.length >= 2) {
            alert('Non-subscribers can only collect up to two files in this folder. Consider subscribing to collect unlimited files.');
            return;
        }

        const originalFileRef = ref(storage, fileRefPath);
        const fileDownloadUrl = await getDownloadURL(originalFileRef);
        const response = await fetch(fileDownloadUrl);
        const originalFileBlob = await response.blob();

        const generateUniqueFileName = async (originalName: string): Promise<string> => {
            const listRef = ref(storage, `${folder}/`);
            const res = await listAll(listRef);
            const existingFiles = res.items
                .filter((item) => item.name.startsWith(`${currentUserUID}_`))
                .map((item) => item.name.replace(`${currentUserUID}_`, ''));

            let newFileName = originalName;
            let counter = 1;
            while (existingFiles.includes(newFileName)) {
                const fileNameWithoutExt = originalName.substring(0, originalName.lastIndexOf('.'));
                const fileExt = originalName.substring(originalName.lastIndexOf('.'));
                newFileName = `${fileNameWithoutExt}(${counter})${fileExt}`;
                counter++;
            }
            return newFileName;
        };

        const uniqueFileName = await generateUniqueFileName(fileName);
        const newFilePath = `${folder}/${currentUserUID}_${uniqueFileName}`;

        const newFileRef = ref(storage, newFilePath);
        await uploadBytes(newFileRef, originalFileBlob);

        // Ask user if they want to share the file
        const shareWithPublic = window.confirm('Do you want to share this file with the public?');

        // Update metadata for the new file
        const customMetadata = {
            customMetadata: {
                UserID: currentUserUID,
                DocumentType: documentType,
                ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
            },
        };

        await updateMetadata(newFileRef, customMetadata);

        alert(`File has been copied to your ${folder} folder as "${uniqueFileName}".`);
    } catch (error) {
        console.error('Error collecting the file:', error);
        alert('Failed to collect the file. Please try again.');
    }
};

  return (
    <div className="container">
      <NavBar userImage={userImage} onProfileLoad={handleProfileLoad} />

      <div className="main-content">
        <Sidebar onLogout={handleLogout} onHomeClick={handleHomeClick} />

        <div className="content-inner">
          {/* Search Bar */}
          <div className="search-bar-container">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              className="search-input"
              placeholder="Search files..."
            />
            <FaSearch className="search-icon" />
          </div>

          {/* Document Type Dropdown */}
          <select
            value={selectedDocType}
            onChange={(e) => handleSelectDocType(e.target.value)}
            style={{ marginBottom: '20px' }}
          >
            <option value="All">All</option>
            <option value="Note">Notes</option>
            <option value="Question">Note Questions</option>
            <option value="Subject">Library Subjects</option>
            <option value="Summary">Note Summaries</option>            
          </select>

          <div className="short-file-list-container">
            <ul className="file-list">
              {filteredFiles.map((file, index) => {
                const isLongWithoutSpaces = file.name.length > 20 && !file.name.includes(' ');

                // Function to split the file name into exactly two segments based on "-" or "_"
                const splitFileName = (name: string): string => {
                  let splitIndex = name.lastIndexOf('-') > 0 ? name.lastIndexOf('-') : name.lastIndexOf('_');
                  if (splitIndex <= 0 || splitIndex >= name.length - 1) {
                    splitIndex = Math.floor(name.length / 2);
                  }
                  const firstPart = name.slice(0, splitIndex);
                  const secondPart = name.slice(splitIndex);
                  return `${firstPart}\n${secondPart}`;
                };

                return (
                  <li key={index} className="file-list-item">
                    <span className="file-name">
                      {isLongWithoutSpaces ? splitFileName(file.name) : file.name}
                    </span>

                    <div className="file-actions">
                      <div className="button-grid-container-public">
                      <button title="View" onClick={() => handleViewFile(file.url, file.name)} className="view-button">
                          <FaEye size={18} />
                        </button>
                        <button 
                        title="Information" 
                        className="information-button" 
                        onClick={() => handleShowFileInfo(file.refPath)}>
                        <FaInfoCircle size={18} />
                      </button>
                      </div>

                      <div className="menu-container" ref={menuRef}>
                        <button title="Tools" className="menu-button" onClick={() => toggleMenu(index)}>...</button>
                        {openMenuIndex === index && (
                          <div className="menu-content">
                          {!user?.isAnonymous && (   
                           <button className="menu-option" onClick={() => handleCollectFile(file.refPath, file.name, file.documentType)}>
                             <FaFolderOpen size={18} style={{ marginRight: '8px' }} />
                            Collect
                           </button>
                            )}
                        {!user?.isAnonymous && (   
                            <button className="menu-option" onClick={() => handleDownloadFile(file.url)}>
                               <FaDownload size={18} style={{ marginRight: '8px' }} />
                              Download</button>     
                          )}                                                  
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* Modal for viewing both PDF and JSON files */}
          {isFileModalOpen && fileUrl && (
            <div className="modal-overlay">
              <div className="stars"></div>
              <div className="modal-content pdf-modal">
                <div className="pdf-toolbar">
                  <ZoomInButton />
                  <ZoomOutButton />
                  <button onClick={() => setIsFileModalOpen(false)} className="close-viewer-button">
                    Close
                  </button>
                </div>
                <div className="pdf-viewer-container">
                  {fileType === 'pdf' ? (
                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                      <Viewer fileUrl={fileUrl} plugins={[zoomPluginInstance, toolbarPluginInstance]} />
                    </Worker>
                  ) : fileType === 'json' ? (
                    <div className="json-viewer">
                      {/* Fetch and display the JSON content */}
                      <JsonViewer fileUrl={fileUrl} />
                    </div>
                  ) : (
                    <p>Unsupported file format</p>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Modal for displaying file information */}
          {isInfoModalOpen && fileInfo && (
            <div className="modal-overlay">
              <div className="info-modal-content">
                <h2>File Information</h2>
                <p><strong>File Name:</strong> {fileInfo.fileName}</p>
                <p><strong>Created Date:</strong> {fileInfo.createdDate}</p>
                <p><strong>Uploaded By:</strong> {fileInfo.uploadedBy}</p>
                <button className="close-button" onClick={() => setIsInfoModalOpen(false)}>Close</button>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

// JSON Viewer component for displaying JSON files in the modal
const JsonViewer: React.FC<{ fileUrl: string }> = ({ fileUrl }) => {
  const [jsonData, setJsonData] = useState<any>(null);

  useEffect(() => {
    const fetchJsonData = async () => {
      try {
        const response = await fetch(fileUrl);
        const data = await response.json();
        setJsonData(data);
      } catch (error) {
        console.error('Error fetching JSON file:', error);
      }
    };

    fetchJsonData();
  }, [fileUrl]);

  return (
    <div className="json-content">
      <pre>{JSON.stringify(jsonData, null, 2)}</pre>
    </div>
  );
};

export default Explore;
