import React, { useState, useCallback,useEffect,useRef } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { FaCommentDots, FaPaperPlane } from 'react-icons/fa';
import OpenAI from "openai";

const openai = new OpenAI({ 
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
    dangerouslyAllowBrowser: true,
});

interface ChatMessage {
    message: string;
    from: 'user' | 'assistant';
    type?: 'text' | 'image'; 
}

interface Assistant {
    name: string;
    id: string;
    chat: ChatMessage[];
    fileID: string;
    threadID: string; 
    vectorStore:string; 
}

interface PdfViewerModalProps {
  isOpen: boolean;
  pdfUrl: string | null;
  onClose: () => void;
}

const PdfViewerModal: React.FC<PdfViewerModalProps> = ({ isOpen, pdfUrl, onClose }) => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton } = zoomPluginInstance;
  const toolbarPluginInstance = toolbarPlugin();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [assistant, setAssistant] = useState<Assistant | null>(null);
  const [isAssistantLoading, setIsAssistantLoading] = useState(false); // Track assistant loading
  const [isSendDisabled, setIsSendDisabled] = useState(true); // Disable send button until assistant is ready
  const [selectedText, setSelectedText] = useState<string | null>(null);
  const [selectionPosition, setSelectionPosition] = useState<{ top: number, left: number } | null>(null);

  const pdfViewerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleTextSelection = () => {
      const selection = window.getSelection();
      console.log('selection', selection?.toString()); // Log the selection
      
      if (selection && selection.toString().trim() !== '') {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        setSelectedText(selection.toString());
        setSelectionPosition({
          top: rect.top + window.scrollY - 40, // Adjust to position the buttons slightly above the selection
          left: rect.left + window.scrollX,
        });
      } else {
        setSelectedText(null);
        setSelectionPosition(null);
      }
    };

    const pdfViewerElement = pdfViewerRef.current;

    if (pdfViewerElement) {
      // Add mouseup event listener to the text layer of the PDF viewer
      pdfViewerElement.addEventListener('mouseup', handleTextSelection);
    }

    return () => {
      if (pdfViewerElement) {
        pdfViewerElement.removeEventListener('mouseup', handleTextSelection);
      }
    };
  }, []);


  const handleAction = (action: 'question' | 'explain' | 'summary') => {
    if (!selectedText) return;

    let message: string;
    switch (action) {
      case 'question':
        message = `Can you generate a question based on this text: "${selectedText}"?`;
        break;
      case 'explain':
        message = `Can you explain the following text: "${selectedText}"?`;
        break;
      case 'summary':
        message = `Can you summarize the following text: "${selectedText}"?`;
        break;
      default:
        return;
    }

    // Handle the action (e.g., send to the assistant)
    console.log(`Action: ${action}`, `Selected Text: ${selectedText}`);
  };


  const handleChatButtonClick = async () => {
    if (!pdfUrl) {
      console.error("No PDF URL available.");
      return;
    }

    setIsChatOpen((prev) => !prev);
  
    if (!assistant) {
      await createTemporaryAssistant(pdfUrl);  
    }
  };
  

// Function to create a temporary assistant
const createTemporaryAssistant = useCallback(async (pdfUrl: string) => {
    const assistantInstructions = 'You are an assistant to help people unserstands uploaded documents. You should respond to questions based on the document you have.';
    setIsAssistantLoading(true);  // Start assistant loading
    setIsSendDisabled(true);  // Disable send button while loading assistant
    try {
      const assistantResponse = await openai.beta.assistants.create({
        name: 'PDF Assistant',
        instructions: assistantInstructions,
        model: "gpt-4o-mini",
        tools: [{ type: "file_search" }],
        metadata: { username: 'your-username' },
      });
  
      const assistantID = assistantResponse.id;  
      console.log('assistant ID', assistantID);
  
      const threadResponse = await openai.beta.threads.create();
      const threadID = threadResponse.id;
      console.log('thread ID', threadID);
  
      // Upload PDF using pdfUrl
      console.log('pdfURL',pdfUrl)
      const fileResponse = await fetch(pdfUrl);
      const fileBlob = await fileResponse.blob();

      const fileUploadResponse = await openai.files.create({
        file: new File([fileBlob], "document.pdf", { type: fileBlob.type }),  // Create file from the URL
        purpose: "assistants",
      });

      console.log('fileUploadResponse', fileUploadResponse);
  
      const fileID = fileUploadResponse.id;
  
      const vectorStoreResponse = await openai.beta.vectorStores.create({
        name: "Temporary Vector Store",
      });
  
      const vectorStoreID = vectorStoreResponse.id;
  
      console.log('vectorstore ID', vectorStoreID);
      const myVectorStoreFile = await openai.beta.vectorStores.files.create(vectorStoreID, {
        file_id: fileID,
      });
      console.log('my vector store file', myVectorStoreFile);

  
  
      await openai.beta.assistants.update(assistantID, {
        tool_resources: { file_search: { vector_store_ids: [vectorStoreID] } },
      });
  
      setAssistant({
        name: 'Temporary Assistant',
        id: assistantID,  // Store the real assistant ID
        chat: [],
        fileID: fileID,
        threadID: threadID,
        vectorStore: vectorStoreID,
      });
  
      setIsSendDisabled(false);  // Enable send button when assistant is ready
    } catch (error) {
      console.error('Error creating assistant:', error);
    } finally {
      setIsAssistantLoading(false);  // Stop assistant loading
    }
  }, []);

  const removeTemporaryAssistant = async () => {
    if (assistant) {
      try {
        await openai.files.del(assistant.fileID);
        console.log('file ID removed:', assistant.fileID);
  
        await openai.beta.threads.del(assistant.threadID);
        console.log('thread ID removed:', assistant.threadID);
  
        await openai.beta.vectorStores.del(assistant.vectorStore);
        console.log('vector store ID removed:', assistant.vectorStore);
  
        await openai.beta.assistants.del(assistant.id);
        console.log('assistant ID removed:', assistant.id);
  
        // Clear the assistant state by setting it to null
        setAssistant(null);
  
        setIsSendDisabled(true); // Disable send button after removing assistant
  
        // Log the assistant state after it's cleared
        setTimeout(() => {
          console.log('assistant removed:', assistant); // This might still log the old value due to async nature of setState
        }, 0);
      } catch (error) {
        console.error('Error removing assistant:', error);
      }
    }
  };
  
  const retryRequest = async (func: () => Promise<any>, retries = 3, defaultDelay = 1000): Promise<any> => {
    try {
      return await func();
    } catch (error: any) {
      if (error?.status === 429 && retries > 0) {
        const retryAfterMatch = error?.response?.data?.error?.message?.match(/after ([\d.]+) seconds/i);
        const retryAfter = retryAfterMatch ? parseFloat(retryAfterMatch[1]) * 1000 : defaultDelay;
        console.log(`Rate limit exceeded, retrying after ${retryAfter / 1000} seconds...`);
        await new Promise((resolve) => setTimeout(resolve, retryAfter));
        return retryRequest(func, retries - 1, defaultDelay);
      } else {
        throw error;
      }
    }
  };

  const uploadFileToOpenAI = async (file: File) => {
    return await retryRequest(async () => {
      const response = await openai.files.create({
        file,
        purpose: 'assistants',
      });
      return response.id;
    });
  };

  const addAssistantImageMessage = async (message: string, fileIds: string[]) => {
    if (!assistant) return;

    const content: OpenAI.Beta.Threads.Messages.MessageContentPartParam[] = [
      { type: "text", text: message },
      ...fileIds.map(fileId => ({
        type: "image_file" as const,
        image_file: { file_id: fileId },
      })),
    ];

    await retryRequest(async () => {
      await openai.beta.threads.messages.create(
        assistant.threadID,
        {
          role: "user",
          content: content,
        }
      );
    });
  };

  const processAssistantResponse = async (updatedChat: ChatMessage[]) => {
    if (!assistant) return;
    try {
      const runResponse = await retryRequest(async () =>
        openai.beta.threads.runs.create(assistant.threadID, {
          assistant_id: assistant.id,  // Use the real assistant ID here
        })
      );

      let isComplete = false;
      while (!isComplete) {
        const runStatus = await retryRequest(async () =>
          openai.beta.threads.runs.retrieve(assistant.threadID, runResponse.id)
        );
        if (runStatus.status === 'completed') {
          isComplete = true;
          const messages = await retryRequest(async () =>
            openai.beta.threads.messages.list(assistant.threadID)
          );

          if (messages.data.length > 0 && messages.data[0].content.length > 0 && messages.data[0].content[0].type === 'text') {
            const assistantReply = messages.data[0].content[0].text.value;
            const newUpdatedChat: ChatMessage[] = [...updatedChat, { message: assistantReply, from: 'assistant', type: 'text' }];
            
            setAssistant((prev) => prev ? { ...prev, chat: newUpdatedChat } : null);
            setLoading(false);
          }
        } else {
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      }
    } catch (error) {
      console.error('Error processing assistant response:', error);
      setLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (isAssistantLoading) {
      console.log("Assistant is still loading. Please wait.");
      return;
    }

    if (!assistant || !assistant.threadID) {
      console.error("Cannot send message: Assistant or threadID is not available.");
      return;
    }

    if (newMessage.trim() === '' && uploadedFiles.length === 0) return;

    let updatedChat: ChatMessage[] = assistant ? [...assistant.chat] : [];
    let fileQuestion = newMessage;

    const filePromises = uploadedFiles.map(async (file) => {
      const fileUrl = URL.createObjectURL(file);
      fileQuestion = `<img src="${fileUrl}" alt="Uploaded file" style="max-width:100%; height: auto;">\n\n` + fileQuestion;

      const uploadedFileId = await uploadFileToOpenAI(file);
      return { fileUrl, fileId: uploadedFileId };
    });

    if (uploadedFiles.length > 0) {
      try {
        const fileData = await Promise.all(filePromises);
        const fileIds = fileData.map(file => file.fileId);

        if (fileQuestion.trim() !== '') {
          updatedChat.push({ message: fileQuestion, from: 'user', type: 'text' });
        }

        setAssistant((prev) => prev ? { ...prev, chat: updatedChat } : null);

        setLoading(true);
        await addAssistantImageMessage(fileQuestion, fileIds);
        await processAssistantResponse(updatedChat);
      } catch (error) {
        console.error('Error uploading files:', error);
        return;
      }
    } else {
      if (newMessage.trim() !== '') {
        updatedChat.push({ message: newMessage, from: 'user', type: 'text' });
      }

      setAssistant((prev) => prev ? { ...prev, chat: updatedChat } : null);
      setLoading(true);

      try {
        const content = [{ type: 'text' as const, text: newMessage }];
        await retryRequest(async () => {
          await openai.beta.threads.messages.create(assistant!.threadID, { role: 'user', content });
        });
        await processAssistantResponse(updatedChat);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }

    setNewMessage('');
    setUploadedFiles([]);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const filesArray = Array.from(e.target.files);
      setUploadedFiles((prevFiles) => [...prevFiles, ...filesArray]);
    }
  };

  if (!isOpen || !pdfUrl) return null;

  return (
    <div className="modal-overlay">
      <div className={`pdf-viewer-model ${isChatOpen ? 'expanded' : 'shrink'}`}>
        <div className="pdf-toolbar">
          <ZoomInButton />
          <ZoomOutButton />

          {/* Chat Button */}
          <button
            className="chat-button"
            onClick={() => handleChatButtonClick()} 
            style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '10px' }}
            title="Open Chat"
            disabled={isAssistantLoading} // Disable the button while loading
          >
            {isAssistantLoading ? "Loading..." : <FaCommentDots size={18} />}
          </button>

          <button
            onClick={() => {
                setIsChatOpen(false); 
                removeTemporaryAssistant(); // Remove the assistant before closing the viewer
                onClose(); // Trigger the onClose prop to close the viewer modal
            }}
            className="close-viewer-button"
            >
            Close
        </button>

        </div>

        <div className={`modal-container ${isChatOpen ? 'expanded' : 'shrink'}`}>
          <div className="pdf-viewer-container">
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
              <Viewer fileUrl={pdfUrl} plugins={[zoomPluginInstance, toolbarPluginInstance]} />
            </Worker>
          </div>

          {isChatOpen && (
            <div className="chat-window">
            <div className="chat-header">
            <h3 style={{ color: 'white' }}>Chat</h3>
            <button 
                className="cancel-chat-button" 
                onClick={() => {
                setIsChatOpen(false);  // Close the chat window
                removeTemporaryAssistant();  // Remove the assistant
                }}
                style={{ background: 'none', border: 'none', cursor: 'pointer', color: 'red' }}
            >
                Cancel
            </button>
            </div>

              <div className="chat-content">
                {assistant?.chat.map((chatMsg, index) => (
                  <p key={index} style={{ color: chatMsg.from === 'user' ? 'blue' : 'green' }}>
                    {chatMsg.message}
                  </p>
                ))}
              </div>

              <div className="chat-input-container">
                <label 
                  title="Upload" 
                  htmlFor="file-upload" 
                  style={{ cursor: 'pointer', alignSelf: 'flex-end', color: 'white' }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                  </svg>
                </label>
                <input id="file-upload" type="file" multiple onChange={handleFileUpload} style={{ display: 'none' }} />
                <textarea
                    value={newMessage}
                    onChange={(e) => {
                        setNewMessage(e.target.value);
                        
                        // If the input is empty, reset the height to 30px
                        if (e.target.value === '') {
                        e.target.style.height = '30px';
                        } else {
                        e.target.style.height = 'auto'; // Let the height adjust dynamically
                        e.target.style.height = `${Math.min(e.target.scrollHeight, 300)}px`; // Cap the height at 300px
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSendMessage();
                        }
                    }}
                    placeholder="Type a message"
                    className="chat-input"
                    rows={2}
                    disabled={isSendDisabled} // Disable sending until assistant is ready
                    style={{ height: '30px' }} // Initialize the height to 30px
                    />

                <button
                  className="chat-send-button"
                  onClick={() => (loading ? setLoading(false) : handleSendMessage())}
                  aria-label={loading ? 'Stop streaming' : 'Send message'}
                  disabled={isSendDisabled} // Disable sending until assistant is ready
                >
                  {loading ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                      <circle cx="8" cy="8" r="7" stroke="currentColor" strokeWidth="2" fill="none" />
                      <rect x="6" y="6" width="4" height="4" fill="currentColor" />
                    </svg>
                  ) : (
                    <FaPaperPlane size={20} />
                  )}
                </button>
              </div>
            </div>
          )}
          {/* Show action buttons near the selected text */}
          {selectedText && selectionPosition && (
            <div
              style={{
                position: 'absolute',
                top: `${selectionPosition.top}px`,
                left: `${selectionPosition.left}px`,
                zIndex: 1000,
                backgroundColor: '#333',
                padding: '5px',
                borderRadius: '5px',
              }}
            >
              <button
                style={{ margin: '0 5px', cursor: 'pointer', color: 'white' }}
                onClick={() => handleAction('question')}
              >
                Question
              </button>
              <button
                style={{ margin: '0 5px', cursor: 'pointer', color: 'white' }}
                onClick={() => handleAction('explain')}
              >
                Explain
              </button>
              <button
                style={{ margin: '0 5px', cursor: 'pointer', color: 'white' }}
                onClick={() => handleAction('summary')}
              >
                Summary
              </button>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default PdfViewerModal;
