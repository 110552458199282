import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc,getDocs, collection, query, onSnapshot, updateDoc,deleteDoc,QueryDocumentSnapshot  } from 'firebase/firestore';
import { auth, storage } from '../firebaseConfig';
import { ref, uploadBytes, getDownloadURL, deleteObject, listAll, StorageReference, uploadString,updateMetadata } from 'firebase/storage';
import { deleteUser } from 'firebase/auth';
import { FaUser, FaCreditCard, FaCog, FaTrash, FaBell,FaTools } from 'react-icons/fa'; 
import { MdOutlineListAlt } from 'react-icons/md'; 
import Logo from '../assets/logo.png';
import './Base.css';

interface NavBarProps {
  userImage: string;
  onProfileLoad: (firstName: string, lastName: string, userImage: string, bio: string) => void;
}

interface AdminUser {
  emails: string[];
}

interface Notification {
  id: string;
  receiverId: string;
  senderId: string;
  fileUrl: string;
  fileName: string;
  fileType: string;
  message: string;
  status: string;
  createdAt: any;
  isRead: boolean; 
  question: string;
  answer: string;
  options?: { [key: string]: string }; // Change from array to object
}

interface QuestionType {
  question: string;
  options?: { [key: string]: string };
  answer: string;
}

const NavBar: React.FC<NavBarProps> = ({ userImage, onProfileLoad }) => {
  const navigate = useNavigate();
  const isProfileFetched = useRef<boolean>(false);
  const [showMenu, setShowMenu] = useState(false);
  const [userName, setUserName] = useState('');
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isLibraryModalOpen, setIsLibraryModalOpen] = useState(false); // Added state for subject selection modal
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
  const [subjects, setSubjects] = useState<string[]>([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState<boolean | null>(null); 
  const menuRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<HTMLDivElement>(null);
  const [adminModalOpen, setAdminModalOpen] = useState(false); 
  const [anonymousUserCount, setAnonymousUserCount] = useState<number>(0);
  const [starterUserCount, setStarterUserCount] = useState<number>(0);
  const [premiumUserCount, setPremiumUserCount] = useState<number>(0);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  // Toggle Notification Panel
  const toggleNotifications = () => {
    setShowNotifications((prev) => !prev);
    if (!showNotifications) {
      markAllNotificationsAsRead();
    }
  };

  // Toggle Navbar Menu
  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const menuClicked = menuRef.current && menuRef.current.contains(event.target as Node);
      const notificationClicked = notificationRef.current && notificationRef.current.contains(event.target as Node);
      
      // Close both menus if clicking outside
      if (!menuClicked && !notificationClicked) {
        setShowMenu(false);
        setShowNotifications(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, notificationRef]);
  

  const markAllNotificationsAsRead = async () => {
    const db = getFirestore();
    const updatedNotifications = notifications.map((notification) => {
      if (!notification.isRead) {
        updateDoc(doc(db, 'notifications', notification.id), { isRead: true });
      }
      return { ...notification, isRead: true };
    });
    setNotifications(updatedNotifications);
    setUnreadCount(0);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser || currentUser.isAnonymous || isProfileFetched.current) {
        return;
      }

      try {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const firstName = userData?.firstName || '';
          const lastName = userData?.lastName || '';
          const imageUrl = userData?.imageUrl || userImage;
          const bio = userData?.bio || '';
          const subscriptionStatus = userData?.subscriptionStatus ?? null; 
          setUserName(`${firstName} ${lastName}`);
          setSubscriptionStatus(subscriptionStatus); 
          onProfileLoad(firstName, lastName, imageUrl, bio);
          isProfileFetched.current = true;
        }
        // Check if the user is an admin
        const adminDocRef = doc(db, 'admin', 'auth');
        const adminDoc = await getDoc(adminDocRef);
        if (adminDoc.exists()) {
          const adminData = adminDoc.data() as AdminUser;
          if (adminData.emails.includes(currentUser.email || '')) {
            setIsAdmin(true);
          }
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, [userImage, onProfileLoad]);

  const openAdminModal = async () => {
    setAdminModalOpen(true);
    try {
        const db = getFirestore();
        
        // Get the count of anonymous users
        const anonymousUsersDocRef = doc(db, 'admin', 'anonymousUsers');
        const anonymousUsersDoc = await getDoc(anonymousUsersDocRef);
        if (anonymousUsersDoc.exists()) {
            const count = anonymousUsersDoc.data()?.counts || 0;
            setAnonymousUserCount(count);
        }

        // Get the counts of starter and premium users
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);

        let starterCount = 0;
        let premiumCount = 0;

        usersSnapshot.docs.forEach((userDoc) => {
            const userData = userDoc.data();

            // Count premium users (subscriptionStatus is true)
            if (userData.subscriptionStatus === true) {
                premiumCount++;
            }
            // Count starter users (not premium and not anonymous)
            else if (userData.anonymousUser !== true) {
                starterCount++;
            }
        });

        // Set the counts
        setStarterUserCount(starterCount);
        setPremiumUserCount(premiumCount);

    } catch (error) {
        console.error('Error fetching user counts:', error);
    }
};


  const cleanAnonymousUserFiles = async () => {
    const db = getFirestore();
    const usersCollection = collection(db, 'users');
  
    // Use getDocs to fetch multiple documents, not getDoc
    const usersSnapshot = await getDocs(usersCollection);
  
    const promises = usersSnapshot.docs.map(async (docSnapshot: QueryDocumentSnapshot) => {
      const userData = docSnapshot.data();
      const userUID = docSnapshot.id;
  
      if (userData.anonymousUser === true) {
        // Delete user's document
        await deleteDoc(doc(db, 'users', userUID));
  
        // Delete user's files in storage
        const folders = ['uploads', 'Q&A', 'library', 'summaries'];
        const deletionPromises = folders.map(async (folder) => {
          const folderRef = ref(storage, `${folder}/`);
          const res = await listAll(folderRef);
          const userFiles = res.items.filter((item) => item.name.includes(userUID));
  
          await Promise.all(
            userFiles.map(async (item: StorageReference) => {
              const fileRef = ref(storage, item.fullPath);
              await deleteObject(fileRef);
            })
          );
        });
  
        await Promise.all(deletionPromises);
      }
    });
  
    await Promise.all(promises);
    alert('Anonymous user files cleaned successfully!');
  };


  useEffect(() => {
    const fetchNotifications = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      const db = getFirestore();
      const notificationsRef = collection(db, 'notifications');
      const q = query(notificationsRef);

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedNotifications = snapshot.docs
          .map(doc => {
            const data = doc.data() as Notification;
            const createdAt = data.createdAt?.toDate ? data.createdAt.toDate() : new Date();
            return { ...data, id: doc.id, createdAt };
          })
          .filter((notification: Notification) => notification.receiverId === currentUser.uid);
      
        setNotifications(fetchedNotifications);

        const unreadCount = fetchedNotifications.filter(notification => !notification.isRead).length;
        setUnreadCount(unreadCount);
      });

      return () => unsubscribe();
    };

    fetchNotifications();
  }, []);

  const handleAcceptShare = async (
    notificationId: string,
    fileUrl: string,
    fileName: string,
    fileType: string
  ) => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
    const receiverUID = currentUser.uid;
    const firestore = getFirestore();
    console.log('code reaching here')

    if (fileType === 'question') {
      const listRef = ref(storage, 'library/');
      const res = await listAll(listRef);
      const userSubjects = res.items
        .filter((item) => item.name.startsWith(`${receiverUID}_`))
        .map((item) => item.name.split('_').slice(1, -1).join('_'));
      setSubjects(userSubjects);
      setIsLibraryModalOpen(true);
    }

    if (fileType === 'note' || fileType === 'subject' || fileType === 'summary' ) {
      try {
        const sharedFileRef = ref(storage, fileUrl);
        const generateUniqueFileName = async (
          originalName: string,
          folder: string
        ): Promise<string> => {
          const listRef = ref(storage, `${folder}/`);
          const res = await listAll(listRef);
          const existingFiles = res.items
            .filter((item) => item.name.startsWith(`${receiverUID}_`))
            .map((item) => item.name.replace(`${receiverUID}_`, ''));
    
          let newFileName = originalName;
          if (fileType === 'note') {
            newFileName = originalName;
          } else if (fileType === 'subject') {
            newFileName = originalName.substring(0, originalName.lastIndexOf('.')) + '_qna.json';
          } else if (fileType === 'summary'){
            newFileName = originalName;
          }
    
          let counter = 1;
          while (existingFiles.includes(newFileName)) {
            const fileNameWithoutExt = originalName.substring(0, originalName.lastIndexOf('.'));
            const fileExt = originalName.substring(originalName.lastIndexOf('.'));
            
            if (fileType === 'note') {
              newFileName = `${fileNameWithoutExt}(${counter})${fileExt}`;
            } else if (fileType === 'subject') {
              newFileName = `${fileNameWithoutExt}(${counter})_qna${fileExt}`; 
            } else if (fileType === 'summary') {
              newFileName = `${fileNameWithoutExt}(${counter})${fileExt}`;
            }
            counter++;
          }
          return newFileName;
        };
    
        const fileDownloadUrl = await getDownloadURL(sharedFileRef);
        const response = await fetch(fileDownloadUrl);
        const sharedFileBlob = await response.blob();
        let uniqueFileName;
        let uploadPath;

        if (fileType === 'note') {
          uniqueFileName = await generateUniqueFileName(fileName, 'uploads');
          uploadPath = `uploads/${receiverUID}_${uniqueFileName}`;
        } else if (fileType === 'subject') {
          uniqueFileName = await generateUniqueFileName(fileName, 'library');
          uploadPath = `library/${receiverUID}_${uniqueFileName}`;
        } else if (fileType === 'summary') {
          uniqueFileName = await generateUniqueFileName(fileName, 'summaries');
          uploadPath = `summaries/${receiverUID}_${uniqueFileName}`;
        }
        const newFileRef = ref(storage, uploadPath);
        await uploadBytes(newFileRef, sharedFileBlob);
        const shareWithPublic = window.confirm('Do you want to share this file with the public?');

        // After the file upload, update its metadata
        const customMetadata = {
          customMetadata: {
            ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
          },
        };
    
        // Update the file's metadata with the custom data
        await updateMetadata(newFileRef, customMetadata);
    
        await updateDoc(doc(firestore, 'notifications', notificationId), { status: 'accepted' });
    
        await updateDoc(doc(firestore, 'notifications', notificationId), {
          status: 'accepted',
          message: `File "${uniqueFileName}" has been accepted and added to your ${fileType === 'note' ? 'notes' : 'library'}.`,

        });
        alert(`File sharing accepted and added to your ${fileType === 'note' ? 'notes' : 'library'} as "${uniqueFileName}".`);


    } catch (error) {
      console.error('Error accepting shared file:', error);
      alert('Failed to accept the file. Please try again.');
    }
  }

  };
  
  const handleRejectShare = async (notificationId: string) => {
    const firestore = getFirestore();
    try {
      // Update the notification status to 'rejected'
      await updateDoc(doc(firestore, 'notifications', notificationId), { status: 'rejected' });

      // Send notification back to the sender
      await updateDoc(doc(firestore, 'notifications', notificationId), {
        status: 'rejected',
        message:'The question has been rejected.',
      });

      alert('Question sharing rejected.');
    } catch (error) {
      console.error('Error rejecting question share:', error);
    }
  };


  const addQuestionToSubject = async ( notificationId: string,  question: string,options: { [key: string]: string } | undefined, answer: string) => {
    if (!selectedSubject) return;
  
    const currentUser = auth.currentUser;
    if (!currentUser) return;
  
    const subjectFileName = `library/${currentUser.uid}_${selectedSubject}_qna.json`;
    const subjectRef = ref(storage, subjectFileName);
  
    try {
      const url = await getDownloadURL(subjectRef);
      const response = await fetch(url);
      const existingData = await response.json();
      const firestore = getFirestore();
    
        const newQuestion: QuestionType = {
          question: question,
          answer: answer,
          options: options || {} // Handle case where options may be undefined
        };
    
        console.log('new question',newQuestion)
    
        const updatedData = [...existingData, newQuestion];
    
        await uploadString(subjectRef, JSON.stringify(updatedData), 'raw', {
          contentType: 'application/json',
        });
    
        alert('Question added to the library successfully!');
    
        await updateDoc(doc(firestore, 'notifications', notificationId), { status: 'accepted' });
    
        await updateDoc(doc(firestore, 'notifications', notificationId), {
          status: 'accepted',
          message: `Question "${question}" has been accepted and added to your library.`,
    
        });

      } catch (error) {
        console.error('Error adding question to library:', error);
        alert('Failed to add question to the library.');
      }
    
    setIsLibraryModalOpen(false);
  };

  const formatDate = (date: Date) => {
    return date.toLocaleString();
  };

  const handleRemoveAccount = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
  
    const confirmDelete = window.confirm(
      'Are you sure you want to delete your account? This action will remove all your files and cannot be undone.'
    );
  
    if (confirmDelete) {
      try {
        const userUID = currentUser.uid;
        const storagePaths = ['uploads', 'library', 'Q&A', 'summaries'];
        const db = getFirestore();
  
        // Function to delete files from a specific storage folder
        const deleteFilesInFolder = async (folderPath: string) => {
          const folderRef = ref(storage, folderPath);
          const res = await listAll(folderRef);
  
          // Filter files that belong to the user and delete them
          const userFiles = res.items.filter((item: StorageReference) => item.name.includes(userUID));
          await Promise.all(
            userFiles.map(async (item: StorageReference) => {
              const fileRef = ref(storage, item.fullPath);
              await deleteObject(fileRef).catch((error) => console.error(`Error deleting ${item.name} from ${folderPath}:`, error));
            })
          );
        };
  
        // Iterate through each folder and delete user's files
        await Promise.all(storagePaths.map(deleteFilesInFolder));
  
        // Remove the user's document from Firestore
        await deleteDoc(doc(db, 'users', userUID));
        console.log('user document deleted')
        // Delete the user from Firebase Authentication
        await deleteUser(currentUser);
  
        navigate('/');
      } catch (error) {
        console.error('Error removing account:', error);
        alert('Failed to delete account. Please try again.');
      }
    }
  };
  

  return (
    <div className="navbar">
      <div className="navbar-left">
        <img 
          src={Logo} 
          alt="App Logo" 
          className="navbar-logo"
          onClick={() => navigate('/home')} 
        />
        <h1 className="navbar-title" onClick={() => navigate('/home')}>
          CompreQnA
        </h1>
      </div>
      <div className="navbar-right">
        {!auth.currentUser?.isAnonymous && auth.currentUser && (
          <>
            <div className="notification-wrapper"  title="Notifications" >
              <FaBell 
                className="navbar-notification-icon"
                size={32} 
                onClick={toggleNotifications}
              />
              {unreadCount > 0 && (
                <span className="notification-count">
                  {unreadCount}
                </span>
              )}
            </div>


            {showNotifications && (
              <div className="notification-panel" ref={notificationRef}>
                {notifications.length === 0 ? (
                  <p>No new notifications</p>
                ) : (
                  notifications.map(notification => (
                    <div 
                      key={notification.id} 
                      className={`notification-item ${!notification.isRead ? 'unread-notification' : ''}`}
                    >
                      <p><strong>{notification.message}</strong></p>
                      <p className="notification-date">{formatDate(notification.createdAt)}</p>

                      {isLibraryModalOpen && (
                          <div className="modal-overlay">
                            <div className="modal-content stylish-modal">
                              <h3 className="modal-title">Select Subject to Add Question</h3>

                              <select
                                value={selectedSubject || ''}
                                onChange={(e) => setSelectedSubject(e.target.value)}
                                className="stylish-select"
                              >
                                <option value="" disabled>Select a subject</option>
                                {subjects.sort().map((subject, index) => (
                                  <option key={index} value={subject}>{subject}</option>
                                ))}
                              </select>

                              <div className="modal-actions">
                                <button className="cancel-button stylish-button" onClick={() => setIsLibraryModalOpen(false)}>
                                  Cancel
                                </button>
                                <button className="add-button stylish-button" onClick={() => addQuestionToSubject(notification.id, notification.question,notification.options,notification.answer)}>
                                  Add to Library
                                </button>
                              </div>
                            </div>
                          </div>
                        )}

                      {notification.status === 'pending' && (
                        <div>
                          <button onClick={() => handleAcceptShare(notification.id, notification.fileUrl, notification.fileName, notification.fileType)}>Accept</button>
                          <button onClick={() => handleRejectShare(notification.id)}>Reject</button>
                        </div>
                      )}
                      {notification.status !== 'pending' && <p>Status: {notification.status}</p>}
                    </div>
                  ))
                )}
              </div>
            )}

            <img
              src={userImage}
              alt="User Thumbnail"
              className="navbar-user-image"
              onClick={toggleMenu}
            />
            {showMenu && (
              <div className="navbar-dropdown-menu" ref={menuRef}>
                <p className="navbar-dropdown-item" onClick={() => navigate('/profile')}>
                  <FaUser className="navbar-dropdown-icon" />
                  <strong>{userName}</strong>
                </p>
                <p className="navbar-dropdown-item" onClick={() => navigate('/my-plan')}>
                  <MdOutlineListAlt className="navbar-dropdown-icon" />
                  My Plan
                </p>

                {subscriptionStatus === true && (
                  <p className="navbar-dropdown-item" onClick={() => navigate('/billing-account')}>
                    <FaCreditCard className="navbar-dropdown-icon" />
                    Subscriptions
                  </p>
                )}

               {isAdmin && (
                  <p className="navbar-dropdown-item" onClick={openAdminModal}>
                    <FaTools className="navbar-dropdown-icon" />
                    Admin
                  </p>
                )}
               
                <p className="navbar-dropdown-item" onClick={() => navigate('/settings')}>
                  <FaCog className="navbar-dropdown-icon" />
                  Settings
                </p>
                <p className="navbar-dropdown-item remove-account" onClick={handleRemoveAccount}>
                  <FaTrash className="navbar-dropdown-icon" />
                  Remove Account
                </p>

              </div>         
            )}
            {adminModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content admin-modal">
                        <h2 style={{ color: 'white' }}>Admin Management</h2>
                        <p style={{ color: 'white' }}>Counts of Anonymous Users: {anonymousUserCount}</p>
                        <p style={{ color: 'white' }}>Counts of Starter Plan Users: {starterUserCount}</p>
                        <p style={{ color: 'white' }}>Counts of Premium Plan Users: {premiumUserCount}</p>                  
                        <button onClick={cleanAnonymousUserFiles}>Clean Anonymous User Files</button>
                        <button onClick={() => setAdminModalOpen(false)}>Cancel</button>
                    </div>
                </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NavBar;
