import React, { useState, useEffect, useRef } from 'react';
import { auth, storage } from '../firebaseConfig';
import { ref, uploadBytes, listAll } from 'firebase/storage';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import NavBar from './NavBar';
import Sidebar from './Sidebar'; 
import './Base.css';
import { useNavigate } from 'react-router-dom';
import upload_blue from "../assets/upload_blue.png";
import * as fontkit from 'fontkit'; 
import { PDFDocument, rgb} from 'pdf-lib';
import { OpenAI } from 'openai';
import Tesseract from 'tesseract.js';

let globalNoteNumber = 1; // Global variable to persist noteNumber across pages
// Constants
const OPENAI_MODEL = 'gpt-4o';
const OCR_LANGUAGE = 'eng';
const OPENAI_SYSTEM_MSG = 'You are an assistant that enhances OCR text.';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY, 
  dangerouslyAllowBrowser: true, // Ensures browser compatibility
});

const ocrWithTesseract = (image: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    Tesseract.recognize(image, OCR_LANGUAGE, {
      logger: (m) => console.log(m), // Optionally log progress
    })
    .then(({ data: { text } }) => {
      resolve(text);
    })
    .catch((error) => {
      reject(`OCR Error: ${error.message}`);
    });
  });
};

// Function to process text with OpenAI API for cleaning
const processTextWithOpenAI = async (ocrText: string): Promise<string> => {
  try {
    const response = await openai.chat.completions.create({
      model: OPENAI_MODEL,
      messages: [
        { role: 'system', content: OPENAI_SYSTEM_MSG },
        { role: 'user', content: `Here is the text extracted from an image: ${ocrText}. Please clean it up and make it more readable.` }
      ],
    });

    return response.choices[0]?.message?.content || 'Unable to process text';
  } catch (error) {
    console.error('Error during text processing:', error);
    return 'Error during text processing.';
  }
};

// Function to convert file to base64
const convertToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event: ProgressEvent<FileReader>) {
      // Ensuring event.target.result is a string as expected
      if (typeof event.target?.result === 'string') {
        const base64String = event.target.result.split(',')[1]; // Extracts the base64 part
        resolve(base64String); // Resolve the promise with the base64 string
      } else {
        reject(new Error("Failed to load file as base64 string"));
      }
    };
    reader.onerror = function (error: ProgressEvent<FileReader>) {
      reject(error); // Reject the promise if there's an error
    };
    reader.readAsDataURL(file); // Read the file as a Data URL
  });
};

// Function to regenerate text with OpenAI API from the original image
const regenerateTextWithOpenAI = async (file: File): Promise<string> => {

  const base64Image = await convertToBase64(file);
  const imageType = file.type;
  try {
    const response = await openai.chat.completions.create({
      model: OPENAI_MODEL, // Or any relevant model capable of image-to-text processing (could also use an image-to-text endpoint)
      messages: [
        { role: 'system', content: 'You are an assistant that can extract text from an image.' },
        { role: 'user', 
          content: [
            { type: "text", text: `What text is shown on the image?` },
            { type: "image_url", image_url: { url: `data:${imageType};base64,${base64Image}`} }
          ],
        }
      ],
    });

    console.log("OpenAI API response", response.choices[0]?.message?.content);

    return response.choices[0]?.message?.content || 'Unable to extract text from image';

  } catch (error) {
    console.error('Error during text extraction:', error);
    return 'Error during text extraction.';
  }
};


const UploadInfo: React.FC = () => {
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState('/path-to-user-image.png');
  const [isDragging, setIsDragging] = useState(false);
  const [textareaContent, setTextareaContent] = useState<string>('');
  const [noteNumber, setNoteNumber] = useState<number>(globalNoteNumber);
  const [userUID, setUserUID] = useState<string>('');
  const [isAnonymous, setIsAnonymous] = useState<boolean>(true);
  const [isSubscriber, setIsSubscriber] = useState<boolean>(false);  
  const isProfileFetched = useRef<boolean>(false); // Using ref instead of state
  const [isConverting, setIsConverting] = useState<boolean>(false); 
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(true); 
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  
  // Disable scrolling when the component is mounted
  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  // Fetch user profile and subscription status
  useEffect(() => {
    const fetchUserProfile = async (uid: string) => {
      const firestore = getFirestore();
      const userDocRef = doc(firestore, 'users', uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        if (userData && userData.noteNumber) {
          setNoteNumber(userData.noteNumber);
        }

        // Check if subscriptionStatus exists and is set to true
        if (userData?.subscriptionStatus === true) {
          setIsSubscriber(true);
        } else {
          setIsSubscriber(false); // Default to false if not subscribed
        }
      }
      isProfileFetched.current = true;
    };

    const resetNoteNumberForAnonymousUser = () => {
      setNoteNumber(1);
    };

    const currentUser = auth.currentUser;
    if (currentUser) {
      setUserUID(currentUser.uid);
      setIsAnonymous(currentUser.isAnonymous);

      if (!currentUser.isAnonymous && !isProfileFetched.current) {
        fetchUserProfile(currentUser.uid);
      } else {
        resetNoteNumberForAnonymousUser();
      }
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the click is outside the textarea and the textarea is empty
      if (textareaRef.current && !textareaRef.current.contains(event.target as Node)) {
        // Only show the button if the textarea is empty
        if (textareaContent.trim() === '') {
          setIsButtonVisible(true); // Show button when clicking outside and textarea is empty
        }
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [textareaContent, textareaRef]); // Depend on textareaContent to check if it's empty
  
  useEffect(() => {
    globalNoteNumber = noteNumber; // Update the global note number
  }, [noteNumber]);

  const saveNoteNumberToDatabase = async (uid: string, newNoteNumber: number) => {
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', uid);
    await setDoc(userDocRef, { noteNumber: newNoteNumber }, { merge: true });
  };

  const handleProfileLoad = (loadedFirstName: string, loadedLastName: string, loadedImageUrl: string,loadBio: string) => {
    setUserImage(loadedImageUrl);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      window.location.href = '/';
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const filterUnsupportedCharacters = (text: string): string => {
    // Replace specific problematic characters with placeholders or remove them
    const filteredText = text
      .replace(/[\u2190-\u21FF]/g, '[ARROW]') // Arrows
      .replace(/[\u2200-\u22FF]/g, '[MATH]') // Mathematical operators
      .replace(/[\u2300-\u23FF]/g, '[TECH]') // Miscellaneous technical symbols
      .replace(/[\u2500-\u257F]/g, '[BOX]') // Box drawing
      .replace(/[\u2580-\u259F]/g, '[BLOCK]') // Block elements
      .replace(/[\u25A0-\u25FF]/g, '[GEOMETRIC]') // Geometric shapes
      .replace(/[\u2600-\u26FF]/g, '[SYMBOL]') // Miscellaneous symbols
      .replace(/[\u2700-\u27BF]/g, '[DINGBAT]') // Dingbats
      .replace(/[\u2B00-\u2BFF]/g, '[SYMBOL]') // Miscellaneous symbols and arrows
      .replace(/[\u2C60-\u2C7F]/g, '[LATIN]') // Latin Extended-C
      .replace(/[\u2E80-\u2EFF]/g, '[CJK]') // CJK Radicals Supplement
      .replace(/[\u2F00-\u2FDF]/g, '[KANGXI]') // Kangxi Radicals
      .replace(/[\u2FF0-\u2FFF]/g, '[IDEOGRAPH]') // Ideographic Description Characters
      .replace(/[\u3000-\u303F]/g, '[CJK SYMBOL]') // CJK Symbols and Punctuation
      .replace(/[\u3040-\u309F]/g, '[HIRAGANA]') // Hiragana
      .replace(/[\u30A0-\u30FF]/g, '[KATAKANA]') // Katakana
      .replace(/[\u3100-\u312F]/g, '[BOPOMOFO]') // Bopomofo
      .replace(/[\u3130-\u318F]/g, '[HANGUL]') // Hangul Compatibility Jamo
      .replace(/[\u3190-\u319F]/g, '[KANBUN]') // Kanbun
      .replace(/[\u31A0-\u31BF]/g, '[BOPOMOFO]') // Bopomofo Extended
      .replace(/[\u31F0-\u31FF]/g, '[KATAKANA]') // Katakana Phonetic Extensions
      .replace(/[\u3200-\u32FF]/g, '[ENCLOSED CJK]') // Enclosed CJK Letters and Months
      .replace(/[\u3300-\u33FF]/g, '[CJK COMPAT]') // CJK Compatibility
      .replace(/[\u3400-\u4DBF]/g, '[CJK UNIFIED]') // CJK Unified Ideographs Extension A
      .replace(/[\u4E00-\u9FFF]/g, '[CJK UNIFIED]') // CJK Unified Ideographs
      .replace(/[\uA000-\uA48F]/g, '[YI]') // Yi Syllables
      .replace(/[\uA490-\uA4CF]/g, '[YI]') // Yi Radicals
      .replace(/[\uA700-\uA71F]/g, '[MODIFIER]') // Modifier Tone Letters
      .replace(/[\uA720-\uA7FF]/g, '[LATIN]') // Latin Extended-D
      .replace(/[\uA800-\uA82F]/g, '[SYLOTI NAGRI]') // Syloti Nagri
      .replace(/[\uA830-\uA83F]/g, '[NUMERIC]') // Common Indic Number Forms
      .replace(/[\uA840-\uA87F]/g, '[PHAGS-PA]') // Phags-pa
      .replace(/[\uA880-\uA8DF]/g, '[SAURASHTRA]') // Saurashtra
      .replace(/[\uA900-\uA92F]/g, '[KAYAH]') // Kayah Li
      .replace(/[\uA930-\uA95F]/g, '[REJANG]') // Rejang
      .replace(/[\uA960-\uA97F]/g, '[HANGUL]') // Hangul Jamo Extended-A
      .replace(/[\uAC00-\uD7AF]/g, '[HANGUL]') // Hangul Syllables
      .replace(/[\uD800-\uDB7F]/g, '[SURROGATE]') // High Surrogates
      .replace(/[\uDB80-\uDBFF]/g, '[SURROGATE]') // High Private Use Surrogates
      .replace(/[\uDC00-\uDFFF]/g, '[SURROGATE]') // Low Surrogates
      .replace(/[\uE000-\uF8FF]/g, '[PRIVATE]') // Private Use Area
      .replace(/[\uF900-\uFAFF]/g, '[CJK COMPAT]') // CJK Compatibility Ideographs
      .replace(/[\uFB00-\uFB4F]/g, '[ALPHABETIC]') // Alphabetic Presentation Forms
      .replace(/[\uFB50-\uFDFF]/g, '[ARABIC]') // Arabic Presentation Forms-A
      .replace(/[\uFE00-\uFE0F]/g, '[VARIATION]') // Variation Selectors
      .replace(/[\uFE10-\uFE1F]/g, '[VERTICAL]') // Vertical Forms
      .replace(/[\uFE20-\uFE2F]/g, '[COMBINING]') // Combining Half Marks
      .replace(/[\uFE30-\uFE4F]/g, '[CJK COMPAT]') // CJK Compatibility Forms
      .replace(/[\uFE50-\uFE6F]/g, '[SMALL FORM]') // Small Form Variants
      .replace(/[\uFE70-\uFEFF]/g, '[ARABIC]') // Arabic Presentation Forms-B
      .replace(/[\uFF00-\uFFEF]/g, '[HALFWIDTH]') // Halfwidth and Fullwidth Forms
      .replace(/[\uFFF0-\uFFFF]/g, '[SPECIAL]'); // Specials    
    return filteredText;
  };

  const handleSubmit = async () => {
    if (textareaContent.trim()) {
      console.log('before processing convert', textareaContent);
      await uploadTextAsPDF(textareaContent);
    } else {
      alert('Please paste some information before submitting.');
    }
  };

  const uploadFile = async (file: File) => {
    const fileName = `${userUID}_${file.name}`;
    const storageRef = ref(storage, `uploads/${fileName}`);

    try {
      const userFilesRef = ref(storage, `uploads/`);
      const fileList = await listAll(userFilesRef);

      if (isAnonymous) {
        // Allow anonymous users to upload a maximum of 2 files
        const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
        if (existingFiles.length >= 2) {
          alert('Anonymous users can only upload up to two files. Registering as a premium user will allow unlimited file uploads.');
          return; // Stop further file upload
        }
      } else {
        // For non-anonymous users, check Firestore for subscriptionStatus
        const firestore = getFirestore();
        const userDocRef = doc(firestore, 'users', userUID);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();

        const isStarterUser = userData?.subscriptionStatus !== true; // Starter user if subscriptionStatus is not true

        if (isStarterUser) {
          // Check if the starter user has already uploaded 2 files
          const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
          if (existingFiles.length >= 2) {
            alert('Starter users can only upload up to two files. Registering as a premium user will allow unlimited file uploads.');
            return; // Stop further file upload
          }
        }
      }

      // Ask the user if they want to share the file with the public
      const shareWithPublic = window.confirm('Do you want to share this file with the public?');

      // Set custom metadata based on the user's selection
      const customMetadata = {
        customMetadata: {
          ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
          UserID:userUID,
          DocumentType:'Note',
        },
      };

      // Proceed with the file upload
      await uploadBytes(storageRef, file, customMetadata);
      
      // Optionally, you can update metadata if needed after the upload
      //await updateMetadata(storageRef, customMetadata);

      alert('File uploaded successfully!');
    } catch (error) {
      console.error('Upload error:', error);
    }
  };
  
  const uploadTextAsPDF = async (text: string) => {
    const pdfDoc = await PDFDocument.create();
  
    // Register fontkit
    pdfDoc.registerFontkit(fontkit as any);
  
    // Load a custom Unicode font from a TTF file
    const fontUrl = process.env.PUBLIC_URL + '/assets/fonts/Roboto-Regular.ttf';
    const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
    const customFont = await pdfDoc.embedFont(fontBytes); // Embed custom font
  
    // Set up an 8x11 inch page in portrait format (8.5 inches wide, 11 inches tall)
    const pageWidth = 8.5 * 72;
    const pageHeight = 11 * 72;
  
    let page = pdfDoc.addPage([pageWidth, pageHeight]);
    const { width, height } = page.getSize();
    const fontSize = 12;
    const lineHeight = fontSize + 4;
    const margin = 50;
    const maxLineWidth = width - margin * 2;
  
    const sanitizedText = filterUnsupportedCharacters(text);
    const paragraphs = sanitizedText.split('\n');
  
    let y = height - margin;
  
    for (const paragraph of paragraphs) {
      const words = paragraph.split(' ');
      let currentLine = '';
  
      for (const word of words) {
        const testLine = currentLine + word + ' ';
        const testLineWidth = customFont.widthOfTextAtSize(testLine, fontSize);
  
        if (testLineWidth > maxLineWidth) {
          if (y - lineHeight < margin) {
            page = pdfDoc.addPage([pageWidth, pageHeight]);
            y = height - margin;
          }
          page.drawText(currentLine, {
            x: margin,
            y: y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });
          currentLine = word + ' ';
          y -= lineHeight;
        } else {
          currentLine = testLine;
        }
      }
  
      if (currentLine.trim()) {
        if (y - lineHeight < margin) {
          page = pdfDoc.addPage([pageWidth, pageHeight]);
          y = height - margin;
        }
        page.drawText(currentLine.trim(), {
          x: margin,
          y: y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        });
        y -= lineHeight;
      }
  
      // After processing each paragraph, add a line break
      y -= lineHeight;
    }
  
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const fileName = `${userUID}_Note${noteNumber}.pdf`;
    const storageRef = ref(storage, `uploads/${fileName}`);
  
    try {
      const userFilesRef = ref(storage, `uploads/`);
      const fileList = await listAll(userFilesRef);
  
      if (isAnonymous) {
        // Allow anonymous users to upload a maximum of 2 files
        const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
        if (existingFiles.length >= 2) {
          alert('Anonymous users can only upload up to two files.');
          return;
        }
      } else {
        // For non-anonymous users, check Firestore for subscriptionStatus
        const firestore = getFirestore();
        const userDocRef = doc(firestore, 'users', userUID);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
  
        const isStarterUser = userData?.subscriptionStatus !== true; // Starter user if subscriptionStatus is not true
  
        if (isStarterUser) {
          // Check if the starter user has already uploaded 2 files
          const existingFiles = fileList.items.filter((item) => item.name.startsWith(userUID));
          if (existingFiles.length >= 2) {
            alert('Starter users can only upload up to two files.');
            return;
          }
        }
      }
  
      // Ask the user if they want to share the file with the public
      const shareWithPublic = window.confirm('Do you want to share this file with the public?');

      // Set custom metadata based on the user's selection
      const customMetadata = {
        customMetadata: {
          ShareWithPublic: shareWithPublic ? 'Yes' : 'No',
          UserID:userUID,
          DocumentType:'Note',
        },
      };

      await uploadBytes(storageRef, blob,customMetadata);

      alert('PDF submitted successfully!');
  
      const newNoteNumber = noteNumber + 1;
      setNoteNumber(newNoteNumber);
      setTextareaContent('');
  
      if (!isAnonymous) {
        saveNoteNumberToDatabase(userUID, newNoteNumber);
      } else {
        globalNoteNumber = newNoteNumber;
      }
    } catch (error) {
      console.error('Upload error:', error);
    }
  };

// Helper function to check OCR text quality using OpenAI
const checkTextQualityWithOpenAI = async (ocrText: string): Promise<boolean> => {
  try {
    const response = await openai.chat.completions.create({
      model: 'gpt-4',
      messages: [
        {
          role: 'system',
          content: 'You are an assistant that can judge OCR text quality.'
        },
        {
          role: 'user',
          content: `Please assess the quality of the following OCR-extracted text: ${ocrText}. Let me know if it is too poor to clean up or if it mostly consists of random characters and abbreviations.`
        }
      ],
    });

    const openAiMessage = response.choices[0]?.message?.content || '';
    console.log('openai message',openAiMessage)

    // Check if OpenAI indicates that the text is poor quality
    const isPoorQuality = openAiMessage.includes(
      "random characters"
    );

    return isPoorQuality;
  } catch (error) {
    console.error('Error during quality assessment:', error);
    // Assume poor quality in case of error to prevent poor output from passing
    return true;
  }
};

const handleImageToText = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0];
  if (file) {
    try {
      setTextareaContent('Processing the image, please wait...');
      setIsConverting(true);

      // Step 1: Extract text using Tesseract.js (without resizing)
      const ocrText = await ocrWithTesseract(file);

      // Step 2: Check the quality of the OCR result using OpenAI
      const isPoorQuality = await checkTextQualityWithOpenAI(ocrText);

      let finalText = '';

      if (isPoorQuality) {
        // Step 3: If poor quality, resize the image and regenerate the text using OpenAI
        finalText = await regenerateTextWithOpenAI(file);
      } else {
        // Step 4: If quality is good, clean the OCR text using OpenAI
        finalText = await processTextWithOpenAI(ocrText);
      }

      setTextareaContent(finalText); // Update the textarea with the final text

    } catch (error) {
      setTextareaContent('An error occurred during image processing. Please try again.');
      console.error('Error:', error);
    } finally {
      setIsConverting(false);
    }
  }
};

  const handleTextareaClick = () => {
    // Hide the Image to Text button when textarea is clicked
    setIsButtonVisible(false);
  };

  
  const handleHomeClick = async () => {
    // Your logic to handle the home click, e.g., navigate to the home page or fetch data
    navigate('/home'); // Example navigation action
  };

  return (
    <div className="container">
      <NavBar 
        userImage={userImage} 
        onProfileLoad={handleProfileLoad} 
      />
      <div className="main-content">
      <Sidebar onLogout={handleLogout} onHomeClick={handleHomeClick} />
        <div className="content">
          <div className="content-inner">
          <div 
            className={`file-drop-area ${isDragging ? 'dragging' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <p style={{ color: 'white', marginBottom: '50px',marginTop: '-20px' }}>Drop your PDF files here or</p> {/* Added marginBottom to adjust spacing */}
            
            <input 
              type="file" 
              onChange={handleFileChange} 
              style={{ display: 'none' }} 
              id="fileInput"
            />
            
            <label htmlFor="fileInput" className="upload-button" style={{ marginBottom: '100px' }}> {/* Added marginTop to move it upward */}
              Choose files
            </label>
          </div>
           <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-105px' }}>
              <img
              src={upload_blue}
              alt=""
              style={{ width: '80px', height: '80px', marginRight: "-10px",marginBottom: "30px" }}
              />
            </div>

            <div className="textarea-container" style={{ position: 'relative'}}>
              <textarea
                value={textareaContent}
                onClick={handleTextareaClick}
                onChange={(e) => setTextareaContent(e.target.value)}
                placeholder="Paste Information Here"
                className="textarea-input"
                readOnly={isConverting}
                ref={textareaRef} // Attach ref to textarea
              />
              {isSubscriber && isButtonVisible && (
                <input 
                  type="file" 
                  onChange={handleImageToText} 
                  style={{ display: 'none' }} 
                  id="imageInput" 
                  disabled={isConverting}
                />
              )}
              {isSubscriber && isButtonVisible && (
                <label
                  htmlFor="imageInput"
                  className="image-upload-button"
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    cursor: isConverting ? 'not-allowed' : 'pointer',
                    opacity: isConverting ? 0.6 : 1,
                  }}
                >
                  {isConverting ? 'Converting...' : 'Image to Text'}
                </label>
              )}
            </div>

            <button onClick={handleSubmit} className="submit-button">
              Submit
            </button>
          </div>          
        </div>
      </div>
    </div>
  );
};

export default UploadInfo;